import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import ArrowDown from '~assets/svg/ArrowDown.svg';

import styles from './Accordion.module.scss';

export const ANIMATE_TO_DURATION = 300;

const Accordion = (data) => {
  const { accordionId, open = false, title, children } = data;
  const [isOpen, setIsOpen] = useState(open);
  const handleClick = () => {
    setIsOpen((state) => !state);
  };

  return (
    <div className={styles.accordionItem} id={accordionId}>
      {data && (
        <>
          <h3>
            <button
              className={styles.button}
              aria-expanded={isOpen}
              aria-controls={`${accordionId}-panel`}
              id={`${accordionId}-button`}
              data-test-id={`${accordionId}-button`}
              onClick={handleClick}
            >
              {title}
              <ArrowDown className={styles.icon} />
            </button>
          </h3>
          <AnimateHeight
            aria-labelledby={`${accordionId}-button`}
            className={styles.panel}
            duration={ANIMATE_TO_DURATION}
            height={isOpen ? 'auto' : 0}
            id={`${accordionId}-panel`}
            data-test-id={`${accordionId}-panel-${
              isOpen ? 'opened' : 'closed'
            }`}
            role='region'
          >
            <div>{children}</div>
          </AnimateHeight>
        </>
      )}
    </div>
  );
};

export default React.memo(Accordion);
