import React from 'react';
import kebabCase from 'lodash/kebabCase';

import CasesList from '~components/Global/CasesList';
import CaseOutcomesSection from '~components/Global/CaseOutcomesSection';
import ContentSection from '~components/Global/ContentSection';
import Gallery from '~components/Global/Gallery';
import ItemsListSection from '~components/Global/ItemsListSection';

import Leadership from '~components/About/Leadership';
import ApplicationForm from '~components/Careers/ApplicationForm';
import JobBenefits from '~components/Careers/JobBenefits';
import OpenPositions from '~components/Careers/OpenPositions';

/* eslint-disable react/display-name */
const componentTypes = {
  capabilities: (data, index) => {
    const props = [{ title: data.label }];
    data.items.forEach((item) => {
      props.push({ title: item.title, about: item.text });
    });
    return <Leadership data={props} key={`section-${index}`} />;
  },
  cases: (data, index) => <CasesList data={data} key={`section-${index}`} />,
  company: (data, index) => (
    <ContentSection {...data} key={`section-${index}`} type='company' />
  ),
  gallery: (data, index) => (
    <Gallery
      data={data}
      sizeSet={{
        sm: [506],
        md: [821],
        lg: [1325],
        xl: [2080], // assumes max window size of 2800px
      }}
      key={`section-${index}`}
    />
  ),
  leadership: (data, index) => (
    <Leadership data={data} key={`section-${index}`} />
  ),
  benefits: (data, index) => (
    <CaseOutcomesSection
      data={data}
      limitItems
      key={`section-${index}`}
      testId={'benefits-module'}
      isFullWidth={data?.items.length % 3 === 2} // breaks into even rows of 3 (with top row of 2)
    />
  ),
  'open-roles': (data, index) => (
    <OpenPositions data={data} key={`section-${index}`} />
  ),
  'list-section': (data, index) => (
    <ItemsListSection
      key={`section-${index}`}
      type={kebabCase(data.title)}
      {...data}
    />
  ),
  'job-benefits': (data, index) => (
    <JobBenefits {...data} key={`section-${index}`} />
  ),
  section: (data, index) => (
    <ContentSection
      label={data.title}
      text={data.text}
      key={`section-${index}`}
      type={kebabCase(data.title)}
    />
  ),
  form: (data, index) => <ApplicationForm data={data} key='application-form' />,
};
/* eslint-enable react/display-name */

function parseComponents(components) {
  return components.map((component, index) => {
    const { data, type } = component;
    if (!Object.keys(componentTypes).includes(type)) {
      return null;
    }
    return componentTypes[type](data, index);
  });
}

export default parseComponents;
