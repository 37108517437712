import { useCallback, useState } from 'react';

export default ({ length, initial = 0 } = {}) => {
  const [activeElement, setActiveElement] = useState(initial);

  const next = useCallback(
    () =>
      setActiveElement((state) => {
        if (length === 2) {
          return state === 0 ? 1 : 0;
        }
        return state === length - 1 ? 0 : state + 1;
      }),
    [length, setActiveElement]
  );
  const previous = useCallback(
    () =>
      setActiveElement((state) => {
        if (length === 2) {
          return state === 0 ? 1 : 0;
        }

        return state === 0 ? length - 1 : state - 1;
      }),
    [length, setActiveElement]
  );

  return {
    state: activeElement,
    next,
    previous,
    setIndex: setActiveElement,
  };
};
