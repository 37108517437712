import React from 'react';

import GridSection from '~components/Global/GridSection';
import Link from '~components/Global/Link';
import List from '~components/Global/List';
import useRouteInfo from '~hooks/useRouteInfo';

import styles from './Press.module.scss';

const Press = ({ data }) => {
  const routeName = useRouteInfo().title;
  if (!data) return null;

  const createListItem = (item) => (
    <>
      <div className={styles.text}>{item.text}</div>
      <Link className={styles.link} to={item?.link?.url}>
        {item?.link?.text}
      </Link>
    </>
  );

  return (
    <GridSection
      data-test-id={`${routeName}-press-module`}
      className={styles.press}
      left={data.title}
      right={
        <List
          items={data.list}
          createListItem={createListItem}
          listItemClassName={styles.pressItem}
        />
      }
    />
  );
};

export default Press;
