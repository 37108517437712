import React, { forwardRef } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import GlobalLogo from '~assets/svg/GlobalLogo.svg';

import styles from './Logo.module.scss';

const Logo = (props, ref) => {
  const { className, id, isLink = true, onClick } = props;

  const logoClasses = cx(styles.Logo, className);

  return isLink ? (
    <Link
      aria-label='link to home'
      className={logoClasses}
      id={id}
      to={'/'}
      data-test-id='global-menu-btn'
      onClick={onClick}
    >
      <GlobalLogo />
    </Link>
  ) : (
    <span
      className={logoClasses}
      id={id}
      data-test-id='global-menu-btn'
      ref={ref}
    >
      <GlobalLogo />
    </span>
  );
};

Logo.displayName = 'Logo';

export default forwardRef(Logo);
