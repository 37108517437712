import React from 'react';
import cx from 'classnames';
import useRouteInfo from '~hooks/useRouteInfo';

import GridSection from '~components/Global/GridSection';
import Markdown from '~components/Global/Markdown';
import TakeawaysGallery from '~components/Cases/TakeawaysGallery';

import styles from './ContentSection.module.scss';

const ContentSection = (props) => {
  const routeName = useRouteInfo().title;
  if (!props) return null;

  const {
    children,
    label,
    text,
    title,
    grid = true,
    type,
    className,
    gallery,
  } = props;
  const testId = props.testId || `${routeName}-${type}-module`;

  if (!grid) {
    return (
      <section data-test-id={testId} className={cx(styles.section, className)}>
        {children && <div className={styles.content}>{children}</div>}
      </section>
    );
  }

  return (
    <>
      <GridSection
        data-test-id={testId}
        className={cx(styles.section, styles.grid, className)}
        left={label}
        right={
          <>
            {title && <h3 className={styles.title}>{title}</h3>}
            {text && <Markdown className={styles.text} source={text} />}
          </>
        }
      />
      {gallery && (
        <div className={styles.galleryContainer}>
          <TakeawaysGallery className={styles.gallery} data={gallery.gallery} />
        </div>
      )}
    </>
  );
};

ContentSection.displayName = 'ContentSection';

export default ContentSection;
