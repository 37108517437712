import { useLocation } from '@reach/router';
import kebabCase from 'lodash/kebabCase';

import routes from '~constants/routes';
import { useSelector } from 'react-redux';

export default () => {
  const location = useLocation();
  const uri = useSelector((state) => state.uri);
  const pathname = uri || location.pathname;
  const routeInfo = {
    title: '404',
  };

  if (pathname === '/') {
    routeInfo.title = 'introduction';
    return routeInfo;
  }

  const currentPath = pathname.split('/').filter(Boolean);
  const route =
    currentPath.length === 1
      ? routes.find((r) => r.uri.includes(currentPath[0]))
      : { title: currentPath[currentPath.length - 1] };

  if (route) {
    route.title = kebabCase(route.title);
    route.parentRoute = currentPath.length >= 2 ? currentPath[0] : null;
  }

  return route || { title: '', parentRoute: '' };
};
