import React from 'react';
import cx from 'classnames';

import Media from '~components/Global/Media';

import styles from './TakeawaysGallery.module.scss';

const getGalleryItemClassName = (item) => {
  const size = item.image?.type || item.video?.type;
  return cx({
    [styles.galleryItem]: true,
    [styles[`${size}`]]: `${size}`,
  });
};

const TakeawaysGallery = ({ className, data }) => {
  if (!data) return null;

  return (
    <div className={cx(styles.gallery, className)}>
      {data?.map((item, index) => (
        <div className={getGalleryItemClassName(item)} key={index}>
          <Media data={item} hasMaxWidth />
        </div>
      ))}
    </div>
  );
};

TakeawaysGallery.displayName = 'TakeawaysGallery';

export default TakeawaysGallery;
