import React from 'react';

import ContentSection from '../ContentSection';

import useRouteInfo from '~hooks/useRouteInfo';

import styles from './ItemsListSection.module.scss';

const ItemsListSection = ({ title, items, type }) => {
  const routeName = useRouteInfo().title;
  if (!items) return null;
  const testId = `${routeName}-${type}-module`;
  const markdown = items.reduce((acc, curr) => acc.concat(`- ${curr}\n`), '');

  return (
    <ContentSection
      testId={testId}
      className={styles.section}
      label={title}
      text={markdown}
    />
  );
};

ItemsListSection.displayName = 'ItemsListSection';

export default ItemsListSection;
