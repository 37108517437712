import React, { useState } from 'react';
import isNil from 'lodash/isNil';
import cx from 'classnames';

import styles from './Field.module.scss';
import useFormField from '~hooks/useFormField';

const Field = ({
  className,
  label,
  name,
  max,
  testId,
  validation = { required: false },
}) => {
  const { id, form, field, hasError } = useFormField(name);
  const [raiseLabel, setRaiseLabel] = useState(false);

  const inputClasses = cx(className, {
    [styles.field]: true,
    [styles.error]: hasError,
    [styles.rise]: raiseLabel,
  });

  const handleBlur = () => {
    setRaiseLabel(!isNil(field) && field !== '');
  };

  const handleFocus = () => {
    setRaiseLabel(true);
  };

  return (
    <>
      <div className={inputClasses}>
        <label className={styles.label} htmlFor={id}>
          {label}
          {validation.required && ' *'}
        </label>
        <div className={styles.inputWrapper}>
          <input
            aria-invalid={hasError}
            className={styles.input}
            data-test-id={testId}
            id={id}
            maxLength={max}
            name={name}
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={form?.register(validation)}
          />
        </div>
        {hasError && form.errors[name] && (
          <span className={cx(styles.message, styles.error)} role='alert'>
            {form?.errors[name].message}
          </span>
        )}
      </div>
    </>
  );
};

Field.displayName = 'Field';

export default Field;
