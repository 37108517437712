// prepares uri for resize image lambda
export function prepareLambdaUri(
  uri,
  width,
  height,
  includeDoubleSized = true
) {
  if (!uri) {
    return '';
  }

  const generateImageSrc = (enlargeTimes) => {
    const imageWidth = width * enlargeTimes;
    const imageHeight = height ? height * enlargeTimes : height;
    const size = height ? `${imageWidth}-${imageHeight}` : imageWidth;
    const lastSlashIndex = uri.lastIndexOf('/');
    return `${uri.substring(0, lastSlashIndex)}/r/${size}/${uri.substring(
      lastSlashIndex + 1
    )}`;
  };
  const imageSrc = generateImageSrc(1);
  const imageSrc2x = generateImageSrc(2);
  return `${imageSrc}${includeDoubleSized ? `, ${imageSrc2x} 2x` : ''}`;
}

// function that prepares srcSet which satisfies resize image lambda uri structure
export function createSrcSet(uri, imageSizeSet, includeDoubleSized = true) {
  const {
    sm: [smWidth, smHeight],
    md: [mdWidth, mdHeight],
    lg: [lgWidth, lgHeight],
    xl: [xlWidth, xlHeight],
  } = imageSizeSet;

  return {
    sm: prepareLambdaUri(uri, smWidth, smHeight, includeDoubleSized),
    md: prepareLambdaUri(uri, mdWidth, mdHeight, includeDoubleSized),
    lg: prepareLambdaUri(uri, lgWidth, lgHeight, includeDoubleSized),
    xl: prepareLambdaUri(uri, xlWidth, xlHeight, includeDoubleSized),
  };
}
