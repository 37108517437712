import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from '~utils/api';
import { setData, setGlobalData } from '~redux/actions';
import { isPreviewMode, isStagingEnv } from '~utils/environments';
import { removeTrailingSlash } from '../utils/helpers';

export const usePageData = (data, page) => {
  const reduxData = useSelector((state) => state[removeTrailingSlash(page)]);
  const globalData = useSelector((state) => state.global);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data || !isStagingEnv) {
      return;
    }

    if (!reduxData) {
      const endpoint =
        !data.endpoint || (isStagingEnv && data.uri === '/404')
          ? `/pages${page}`
          : data.endpoint;
      API.fetchData(endpoint).then((data) => {
        dispatch(setData(data, page));
      });
    }
  }, [data, reduxData]);

  useEffect(() => {
    if (!data || !isStagingEnv) {
      return;
    }

    if (!globalData) {
      API.fetchData(process.env.GATSBY_API_ENDPOINT_GLOBAL).then((data) => {
        dispatch(setGlobalData(data));
      });
    }
  }, [data, globalData]);

  if (!reduxData || (!isPreviewMode && !isStagingEnv)) {
    return data;
  }

  return { ...reduxData, ...globalData };
};
