import variables from '~styles/base/_export.scss';

export const MEDIA_QUERIES = {
  SM: '(min-width: 0)',
  MD: `(min-width: ${variables.breakpointMD})`,
  LG: `(min-width: ${variables.breakpointLG})`,
  XL: `(min-width: ${variables.breakpointXL})`,
};

const BREAKPOINTS = [
  {
    media: MEDIA_QUERIES.SM,
    name: 'sm',
    minWidth: 0,
  },
  {
    media: MEDIA_QUERIES.MD,
    name: 'md',
    minWidth: parseInt(variables.breakpointMD),
  },
  {
    media: MEDIA_QUERIES.LG,
    name: 'lg',
    minWidth: parseInt(variables.breakpointLG),
  },
  {
    media: MEDIA_QUERIES.XL,
    name: 'xl',
    minWidth: parseInt(variables.breakpointXL),
  },
];

export default BREAKPOINTS;
