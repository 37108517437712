import React, { useMemo } from 'react';
import shuffle from 'lodash/shuffle';

import ContentRollerController from '~components/Global/ContentRoller/ContentRollerController';
import Link from '~components/Global/Link';
import SingleOutcome from '~components/Global/SingleOutcome';
import useRouteInfo from '~hooks/useRouteInfo';

import style from './IntroductionCasesGrid.module.scss';

const IntroductionCasesGrid = ({ cases = [], caseLinkLabel }) => {
  const casesWithAnimation = useMemo(
    () => shuffle(cases.filter((c) => c?.facts?.length > 1)),
    [cases]
  );

  const routeName = useRouteInfo().title;
  return (
    <div className={style.wrapper}>
      <ContentRollerController length={casesWithAnimation.length}>
        {({ state, ref, isPlaying }) => (
          <ul ref={ref} className={style.content}>
            {cases.map((item, i) => {
              return (
                <li className={style.listItem} key={item.uri}>
                  <SingleOutcome
                    className={style.singleOutcome}
                    titleClassName={style.singleOutcomeTitle}
                    index={i}
                    linkLabel={caseLinkLabel}
                    {...item}
                    shouldAnimate={
                      isPlaying
                        ? casesWithAnimation.findIndex(
                            (value) => value.uri === item.uri
                          ) === state
                        : false
                    }
                  />
                </li>
              );
            })}
          </ul>
        )}
      </ContentRollerController>
      <Link
        aria-label='View all clients'
        className={style.link}
        data-test-id={`${routeName}-view-all-link`}
        to='/clients'
      >
        View all
      </Link>
    </div>
  );
};

export default IntroductionCasesGrid;
