import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';

import Form from '../../Global/Form';
import GridSection from '../../Global/GridSection';

import formValidations from '~utils/formValidations';
import { setApplicationName } from '~redux/actions';
import { sendJobApplicationEvent } from '~utils/analytics';

import styles from './ApplicationForm.module.scss';
import useRouteInfo from '~hooks/useRouteInfo';
import { sendApplication } from '~utils/api';

const ApplicationForm = ({ data }) => {
  const routeInfo = useRouteInfo();
  const dispatch = useDispatch();
  if (!data) return null;
  const fields = data.map((f) => ({
    type: f.fieldType,
    name: f.fieldName,
    successMessage: 'Nice to meet you',
    validation: formValidations(f),
    ...f,
  }));

  const onSubmit = async (data) => {
    const response = await sendApplication(data);
    if (response?.status) {
      const firstName = data['first-name'] || '';
      const lastName = data['last-name'] || '';
      dispatch(
        setApplicationName(lastName ? `${firstName} ${lastName}` : firstName)
      );
      navigate('/careers/success');
      sendJobApplicationEvent(routeInfo.title);
    }

    return response;
  };

  return (
    <GridSection
      className={styles.section}
      data-test-id='apply-section'
      left='Apply'
      right={
        <Form
          formId='general-application'
          mode='onBlur'
          submit={onSubmit}
          fields={fields}
          button={{ label: 'Submit' }}
          buttonClassName={styles.button}
        />
      }
    />
  );
};

ApplicationForm.displayName = 'ApplicationForm';

export default ApplicationForm;
