import { useEffect, useState } from 'react';

export const useUsingKeyboard = (defaultUsingKeyboard = false) => {
  const [usingKeyboard, setUsingKeyboard] = useState(defaultUsingKeyboard);

  const handlePointerDown = (e) => {
    setUsingKeyboard(false);
  };
  const handeKeyDown = (e) => {
    // Check for meta keys
    if (!e.altKey && !e.ctrlKey && !e.metaKey) setUsingKeyboard(true);
  };

  useEffect(() => {
    document.addEventListener('mouseDown', handlePointerDown);
    document.addEventListener('touchstart', handlePointerDown);
    document.addEventListener('keydown', handeKeyDown);

    return () => {
      document.removeEventListener('mouseDown', handlePointerDown);
      document.removeEventListener('touchstart', handlePointerDown);
      document.removeEventListener('keydown', handeKeyDown);
    };
  }, []);

  return usingKeyboard;
};

export default useUsingKeyboard;
