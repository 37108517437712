import React, { useState } from 'react';
import cx from 'classnames';

import styles from './FileField.module.scss';
import useFormField from '~hooks/useFormField';
import useUsingKeyboard from '~hooks/useUsingKeyboard';

const FileField = ({
  className,
  label,
  name,
  validation,
  fileSize,
  testId,
  fileExtensions,
  successMessage,
}) => {
  const { id, form, field, hasError } = useFormField(name);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const isUsingKeyboard = useUsingKeyboard();

  const hasValue = field?.length > 0;

  const inputClasses = cx(className, styles.field, {
    [styles.error]: hasError,
  });

  return (
    <>
      <div className={inputClasses}>
        <label
          className={cx(styles.label, {
            [styles.hasUpload]: !hasError && field?.length > 0,
            [styles.hasFocus]: isInputFocused && isUsingKeyboard,
          })}
          htmlFor={id}
        >
          <span>
            {!hasError && hasValue && field?.[0]?.name ? field[0].name : label}
          </span>
          {(hasError || !hasValue) && !validation?.required && (
            <span className={styles.optional}>Optional</span>
          )}
          {!hasError && hasValue && (
            <span className={styles.success}>{successMessage}</span>
          )}
        </label>
        <div className={styles.inputWrapper}>
          <input
            type='file'
            accept={fileExtensions ? fileExtensions.join(',') : null}
            aria-invalid={hasError}
            className={styles.input}
            id={id}
            name={name}
            ref={form?.register(validation)}
            onChange={() => form?.trigger(name)}
            onInput={() => {
              if (!isUsingKeyboard) setIsInputFocused(false);
            }}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            aria-describedby={`${name}-helper`}
            data-test-id={testId}
          />
        </div>
        {!hasError && (
          <span
            className={cx(styles.message, styles.helper)}
            id={`${name}-helper`}
          >
            File size of {fileSize}MB
          </span>
        )}
        {hasError && form.errors[name] && (
          <span className={cx(styles.message, styles.error)} role='alert'>
            {form?.errors[name]?.message}
          </span>
        )}
      </div>
    </>
  );
};

FileField.displayName = 'FileField';

export default FileField;
