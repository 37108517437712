import { useEffect } from 'react';

export const useOutsideClick = (ref, handler) => {
  useEffect(() => {
    const docElement = document;
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    if (docElement) {
      docElement.addEventListener('mousedown', listener);
      docElement.addEventListener('touchstart', listener);
      return () => {
        if (docElement) {
          docElement.removeEventListener('mousedown', listener);
          docElement.removeEventListener('touchstart', listener);
        }
      };
    }
  }, [ref, handler]);
};
