module.exports = [
  {
    title: 'Introduction',
    uri: '/',
    endpoint: '/introduction',
  },
  { title: 'Leadership', uri: '/leadership', endpoint: '/leadership' },
  { title: 'Capabilities', uri: '/capabilities', endpoint: '/capabilities' },
  { title: 'Clients', uri: '/clients', endpoint: '/clients' },
  { title: 'Privacy', uri: '/privacy', endpoint: '/privacy' },
  { title: '404', uri: '/404', endpoint: '/404-data' },
  { title: 'Newsletter', uri: '/newsletter', endpoint: '/newsletter' },
  {
    title: 'Newsletter',
    uri: '/newsletter/success',
    endpoint: '/newsletter/success',
  },
  { title: 'Careers', uri: '/careers', endpoint: '/careers' },
  // TODO: add News section once content is available
  // { title: 'News', uri: '/news', endpoint: '/news' },
  { title: 'Contact', uri: '/contact', endpoint: '/contact' },
  {
    title: 'Contact',
    uri: '/contact/success',
    endpoint: '/contact/success',
  },
  {
    title: 'Careers',
    uri: '/careers/success',
    endpoint: '/careers/success',
  },
];
