export default ({
  validationType,
  isRequired,
  errorMessage,
  fileSize,
  errorFileSize,
}) => {
  const validation = {};
  if (isRequired) {
    validation.required = 'Required';
  }

  switch (validationType) {
    case 'email':
      validation.pattern = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: errorMessage || 'Are you sure this is right?',
      };
      break;
    case 'url':
      validation.pattern = {
        // eslint-disable-next-line
        value: /((https?:\/\/)|(https?:\/\/)(www\.)|(www\.))[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        message: errorMessage || 'Are you sure this is right?',
      };
      break;
    case 'file':
      validation.validate = (value) =>
        !value.length ||
        value[0]?.size < fileSize * 1048576 ||
        errorFileSize ||
        `The file exceeds our maximum file size of ${fileSize}MB`;
      break;
    case 'tel':
      validation.pattern = {
        value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        message: 'Are you sure this is right?',
      };
      break;
    default:
      break;
  }

  return validation;
};
