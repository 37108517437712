import React from 'react';
import { AnimatePresence, m as motion, useReducedMotion } from 'framer-motion';

const TextVerticalRoller = ({
  children,
  className,
  motionKey,
  duration = 0.5,
  delay,
}) => {
  const isReducedMotion = useReducedMotion();
  return (
    <AnimatePresence initial={true}>
      <motion.div
        className={className}
        key={motionKey}
        initial={{ y: isReducedMotion ? 0 : '100%' }}
        animate={{ y: 0 }}
        exit={{ y: isReducedMotion ? 0 : '-100%' }}
        transition={{ duration, delay }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

TextVerticalRoller.displayName = 'TextVerticalRoller';

export default TextVerticalRoller;
