import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import shuffle from 'lodash/shuffle';

import ContentRollerText from '../ContentRoller/ContentRollerText';
import useTextMaxHeight from '../ContentRoller/useTextMaxHeight';

import ContentRoller from '~components/Global/ContentRoller';
import useContentController from '~components/Global/ContentRoller/ContentRollerController/useContentController';
import Link from '~components/Global/Link';

import useRouteInfo from '~hooks/useRouteInfo';
import getRenderedSize from '~utils/getRenderedSize';
import { parseHtmlEntities } from '~utils/helpers';

import styles from './SingleOutcome.module.scss';

export const SupportPosition = {
  SUB: 'sub',
  SUP: 'sup',
};

const SingleOutcome = ({
  title,
  uri,
  facts = [],
  shouldAnimate,
  className,
  titleClassName,
  topClassName,
  index,
  linkLabel,
}) => {
  const [titleDimensions, setTitleDimensions] = useState({});
  const containerRef = useRef(null);
  const sortedFacts = useMemo(() => shuffle(facts), [facts]);
  const titles = useMemo(
    () =>
      sortedFacts
        .map((item) => {
          const text = item.largeText.find((i) => i.type === 'text');
          if (!text) return '';
          return text.text;
        })
        .filter(Boolean),
    [sortedFacts]
  );

  const supports = useMemo(
    () =>
      sortedFacts
        .map((item) => {
          const text = item.largeText.find(
            (i) =>
              i.type === SupportPosition.SUP || i.type === SupportPosition.SUB
          );
          if (!text) return {};
          return text;
        })
        .filter(Boolean),
    [sortedFacts]
  );

  const texts = useMemo(
    () => sortedFacts.map((item) => item.text).filter(Boolean),
    [sortedFacts]
  );

  const altTexts = useMemo(
    () =>
      sortedFacts
        .map((item) => item.altText || item['alt_text'])
        .filter(Boolean),
    [sortedFacts]
  );

  const subtitles = useMemo(
    () => sortedFacts.map((item) => item.title).filter(Boolean),
    [sortedFacts]
  );

  const { state, isPlaying, resume, pause } = useContentController({
    length: sortedFacts.length,
  });

  useEffect(() => {
    if (shouldAnimate && !isPlaying) {
      resume();
    } else if (!shouldAnimate && isPlaying) {
      pause();
    }
  }, [shouldAnimate, resume, pause, isPlaying]);

  useEffect(() => {
    if (!titles[state]) {
      return;
    }

    getRenderedSize(
      <ContentRollerText text={titles[state]} className={styles.title} />,
      containerRef.current?.clientWidth
    ).then((dimension) => setTitleDimensions(dimension));
  }, [containerRef.current?.clientWidth, state, titles]);

  const routeName = useRouteInfo().title;

  const textMinHeight = useTextMaxHeight({
    texts,
    className: styles.text,
    containerWidth: containerRef.current?.clientWidth,
  });

  const subtitleMinHeight = useTextMaxHeight({
    texts: subtitles,
    className: styles.subtitle,
    containerWidth: containerRef.current?.clientWidth,
  });

  const hasAltText = altTexts[state] && altTexts[state].length > 0;
  const parsedAltText = parseHtmlEntities(altTexts[state]);
  const ariaLabel = title ? `${title}: ${parsedAltText}` : parsedAltText;

  return (
    <div ref={containerRef} className={cx(styles.container, className)}>
      {/* eslint-disable jsx-a11y/aria-role */}
      <div {...(hasAltText ? { role: 'text', 'aria-label': ariaLabel } : {})}>
        {/* eslint-enable jsx-a11y/aria-role */}
        <div className={cx(styles.top, topClassName)}>
          <ContentRoller
            height={titleDimensions.height}
            text={titles[state]}
            support={supports[state]?.text}
            supportClassName={styles.support}
            supportPosition={supports[state]?.type}
            elementClassName={styles.title}
          />
        </div>
        {title ? (
          <div className={cx(styles.subtitle, titleClassName)}>{title}</div>
        ) : (
          <ContentRoller
            height={subtitleMinHeight}
            text={subtitles[state]}
            elementClassName={styles.subtitle}
          />
        )}
        <ContentRoller
          height={textMinHeight}
          text={texts[state]}
          elementClassName={styles.text}
          containerWidth={containerRef.current?.clientWidth}
        />
      </div>
      {uri && (
        <Link
          aria-label={`See ${title} case study`}
          data-test-id={`${routeName}-${index}-see-case-link`}
          className={styles.link}
          to={uri}
        >
          {linkLabel}
        </Link>
      )}
    </div>
  );
};

SingleOutcome.displayName = 'Header';

export default SingleOutcome;
