import React from 'react';

import GridSection from '~components/Global/GridSection';
import TakeawaysIndex from '~components/Cases/TakeawaysIndex';
import TakeawaysSubsection from '~components/Cases/TakeawaysSubsection';

import useRouteInfo from '~hooks/useRouteInfo';

import styles from './Takeaways.module.scss';

const Takeaways = (props) => {
  const routeName = useRouteInfo().title;
  if (!props) return null;

  const getSubSections = (data) =>
    data.map((section, index) => {
      return (
        <TakeawaysSubsection
          {...section}
          key={`takeaways-subsection-${index}`}
        />
      );
    });

  const getSections = (data) =>
    data.map((section, index) => {
      const { subSections } = section;
      return (
        <section
          data-test-id={`${routeName}-takeaways-details-${index}`}
          className={styles.takeaway}
          id={`takeaway-${index}`}
          key={section.title}
          tabIndex='-1'
        >
          <GridSection
            className={styles.subsection}
            left={
              <h3 className={styles.subsectionTitle}>
                {(index + 1).toString().padStart(2, '0')}.
              </h3>
            }
            right={<h3 className={styles.subsectionTitle}>{section.title}</h3>}
          />
          {subSections && getSubSections(subSections)}
        </section>
      );
    });

  return (
    <section className={styles.takeaways}>
      {props && (
        <TakeawaysIndex testId={`${routeName}-takeaways-list`} {...props} />
      )}
      {props.sections && getSections(props.sections)}
    </section>
  );
};

Takeaways.displayName = 'Takeaways';

export default Takeaways;
