import React from 'react';

import Layout from '~components/Global/Layout';
import PageHeader from '~components/Global/PageHeader';
import Subscribe from '~components/Global/Subscribe';

import styles from './Newsletter.module.scss';

const Newsletter = ({ data, location }) =>
  data ? (
    <Layout hideIndexLink data={data} location={location}>
      <PageHeader
        label={data.label}
        headline={data.headline}
        text={data.text}
        title='Newsletter'
      >
        <Subscribe
          className={styles.subscribe}
          disclaimerText={data.disclaimerText}
        />
      </PageHeader>
    </Layout>
  ) : null;

Newsletter.displayName = 'Newsletter';

export default Newsletter;
