import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import FocusTrap from 'focus-trap-react';

import Logo from '~components/Global/Logo';

import useLockBodyScroll from '~hooks/useLockBodyScroll';
import useWindowResize from '~hooks/useWindowResize';

import styles from './Header.module.scss';

const Header = ({ className, hideLogo = false, location, menuCallback }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { height } = useWindowResize();

  useLockBodyScroll(isMenuOpen);

  useEffect(() => {
    const vh = height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [height]);

  const pages = [
    {
      label: 'Capabilities',
      url: 'capabilities',
      ariaLabel: 'About our capabilities',
    },
    {
      label: 'Leadership',
      url: 'leadership',
      ariaLabel: 'About our leadership',
    },
    {
      label: 'Clients',
      url: 'clients',
      ariaLabel: 'View clients and case studies',
    },
    // TODO: add News section once content is available
    // { label: 'News', url: newsLink, ariaLabel: 'News from AKDM' },
    { label: 'Careers', url: 'careers', ariaLabel: 'View job openings' },
    { label: 'Contact', url: 'contact', ariaLabel: 'Contact us' },
  ];

  const links = [
    {
      url: '/newsletter',
      title: 'Newsletter',
    },
    {
      url: '/privacy',
      title: 'Privacy Policy',
    },
  ];

  const currentPage =
    location &&
    pages.find((page) => {
      const matches = location.pathname.match(/\/(.*?)\//);
      if (matches && matches.length > 1 && matches[1]) {
        return page.url === matches[1];
      } else {
        return false;
      }
    });

  const onNavLogoClick = (e) => {
    if (location.pathname === '/') {
      e.preventDefault();
      setIsMenuOpen(false);
      menuCallback && menuCallback(false);
    }
  };

  const onNavItemClick = (e) => {
    if (e.target.href === location.href) {
      e.preventDefault();
      setIsMenuOpen(false);
      menuCallback && menuCallback(false);
    }
  };

  return (
    <header className={cx(styles.Header, className)} id='header'>
      <FocusTrap active={isMenuOpen}>
        <div className={styles.content}>
          <div className={styles.logoContainer}>
            <Logo
              className={cx(styles.Logo, {
                [styles.hideLogo]: hideLogo,
                [styles.isOpen]: isMenuOpen,
              })}
              id='logo'
              role='button'
              onClick={onNavLogoClick}
            />
          </div>
          <div className={styles.hamburgerContainer}>
            <button
              aria-label={isMenuOpen ? 'Close navigation' : 'Open navigation'}
              className={cx(styles.hamburger, {
                [styles.isOpen]: isMenuOpen,
              })}
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
                menuCallback && menuCallback(!isMenuOpen);
              }}
            >
              <span className={styles.lines} aria-hidden={true}>
                <span className={styles.line}></span>
              </span>
            </button>
          </div>
          <div
            className={cx(styles.navContainer, {
              [styles.isOpen]: isMenuOpen,
            })}
          >
            <nav className={styles.nav}>
              <ul className={styles.navList}>
                {pages.map(({ label, url, ariaLabel }) => (
                  <li
                    key={label}
                    className={cx(styles.navItem, styles.animateItem)}
                  >
                    <Link
                      aria-label={ariaLabel}
                      className={cx(styles.navLink, {
                        [styles.currentNavLink]:
                          currentPage && currentPage.label === label,
                      })}
                      to={
                        (url && `${url.replace(/^\/?(.*)\/?$/, '/$1/')}`) || '/'
                      }
                      onClick={onNavItemClick}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            <ul className={cx(styles.footerLinks, styles.animateItem)}>
              {links.map(({ url, title }) => (
                <li key={`${url}-${title}`}>
                  <Link
                    className={styles.footerLink}
                    to={
                      (url && `${url.replace(/^\/?(.*)\/?$/, '/$1/')}`) || '/'
                    }
                    onClick={onNavItemClick}
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </FocusTrap>
    </header>
  );
};

Header.displayName = 'Header';

export default Header;
