import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import cx from 'classnames';
import difference from 'lodash/difference';

import Link from '~components/Global/Link';
import Image from '~components/Global/Image';
import { getReactNodeText, isStringEqualToNoBreakSpace } from '~utils/helpers';

import styles from './Markdown.module.scss';

const ALLOWED_TYPES = [
  'blockquote',
  'break',
  'delete',
  'emphasis',
  'heading',
  'image',
  'link',
  'list',
  'listItem',
  'paragraph',
  'strong',
  'text',
];

export const MarkdownHeading = (props) => {
  const { level, children } = props;
  const HeadingComponent = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'][level - 1];
  const text = getReactNodeText(children);
  const isEmptyRow = isStringEqualToNoBreakSpace(text);

  return (
    <HeadingComponent
      aria-hidden={isEmptyRow}
      className={cx(styles.heading, styles[HeadingComponent])}
    >
      {children}
    </HeadingComponent>
  );
};

export const MarkdownLink = (props) => {
  const { children, href } = props;

  return (
    <Link to={href} className={styles.link}>
      {children}
    </Link>
  );
};

export const MarkdownListItem = (props) => {
  return <li className={styles.list}>{props.children}</li>;
};

export const MarkdownList = (props) => {
  const { ordered, children } = props;
  return ordered ? (
    <ol className={styles.listWrapper}>{children}</ol>
  ) : (
    <ul className={styles.listWrapper}>{children}</ul>
  );
};

export const MarkdownParagraph = (props) => {
  const text = getReactNodeText(props.children);
  const isEmptyParagraph = isStringEqualToNoBreakSpace(text);

  return (
    <p aria-hidden={isEmptyParagraph} className={styles.paragraph}>
      {props.children}
    </p>
  );
};

export const MarkdownImage = (props) => {
  const { alt, title, src } = props;

  return (
    <Image
      image={{
        uri: src,
        alt: alt || '',
      }}
      title={title}
      inlineImage
    />
  );
};

export const MarkdownQuote = (props) => {
  const text = getReactNodeText(props.children);
  const isEmptyParagraph = isStringEqualToNoBreakSpace(text);

  return (
    <blockquote className={styles.blockquote} aria-hidden={isEmptyParagraph}>
      {text}
    </blockquote>
  );
};

const defaultRenderers = {
  image: MarkdownImage,
  link: MarkdownLink,
  paragraph: MarkdownParagraph,
  heading: MarkdownHeading,
  blockquote: MarkdownQuote,
  listItem: MarkdownListItem,
  list: MarkdownList,
};

const getRenderers = (customRenderers) => {
  const renderers = {
    ...defaultRenderers,
  };

  if (!customRenderers) {
    return renderers;
  }

  Object.keys(customRenderers).forEach((rendererKey) => {
    const customRenderer = customRenderers[rendererKey];
    const rendererComponent = renderers[rendererKey];

    if (rendererComponent) {
      renderers[rendererKey] = customRenderer;
    }
  });

  return renderers;
};

const Markdown = ({
  source,
  allowedTypes,
  skipTypes,
  renderersProps,
  className,
  'data-test-id': testId,
  ...props
}) => {
  const markdownAllowedTypes = useMemo(
    () => difference(allowedTypes, skipTypes),
    [allowedTypes, skipTypes]
  );

  // Newlines (shift + enter) in the CMS are delivered as
  // &nbsp;\n\n, but remark-breaks expects \n
  // replace &amp; with & to improve Voiceover announcing
  const formattedSource = source
    ? source.replace(/&nbsp;\n\n/g, '\n').replace(/&amp;/g, '&')
    : '';

  return (
    <div className={className} data-test-id={testId}>
      <ReactMarkdown
        allowedTypes={markdownAllowedTypes}
        plugins={[breaks]}
        renderers={{
          ...getRenderers(renderersProps),
        }}
        source={formattedSource}
        {...props}
      />
    </div>
  );
};

Markdown.defaultProps = {
  allowedTypes: ALLOWED_TYPES,
  skipTypes: [],
};

Markdown.displayName = 'Markdown';

export default Markdown;
