import React from 'react';
import cx from 'classnames';

import Markdown from '~components/Global/Markdown';

import styles from './QuoteBlock.module.scss';

const QuoteSize = {
  Big: 'big',
  Small: 'small',
};

const Alignment = {
  Center: 'center',
  Left: 'left',
  Right: 'right',
};

export const QuoteBlock = ({
  className,
  quote,
  size,
  alignment,
  hasDashWithLogo,
  ...props
}) => {
  if (!quote) {
    return null;
  }

  const quoteWrapperClasses = cx(
    {
      [styles.big]: size === QuoteSize.Big,
      [styles.dashWithLogo]: hasDashWithLogo,
      [styles[alignment]]: alignment,
    },
    className
  );

  return (
    <div className={quoteWrapperClasses}>
      <div className={styles.text}>
        <Markdown
          renderersProps={{
            paragraph: 'q',
            text: 'q',
          }}
          source={quote.text}
        />
      </div>

      {quote.author && (
        <div className={styles.cite}>
          <div>{quote.author}</div>
          {quote.role && <div>{quote.role}</div>}
        </div>
      )}
    </div>
  );
};

QuoteBlock.defaultProps = {
  size: QuoteSize.Small,
  alignment: Alignment.Center,
};

QuoteBlock.displayName = 'QuoteBlock';

export default QuoteBlock;
