import React from 'react';

import TakeawaysGallery from '~components/Cases/TakeawaysGallery';
import TakeawaysSingleAsset from '~components/Cases/TakeawaysSingleAsset';

import parseSections from '~utils/parseSections';

import styles from './TakeawaysSubsection.module.scss';

/* eslint-disable react/display-name */
const sectionTypes = {
  gallery: (data) => {
    const textSubsections = parseText(data.description?.items);
    return (
      <div className={styles.takeawaysSubsection}>
        {data.description && parseSections(textSubsections)}
        <TakeawaysGallery data={data.gallery} />
      </div>
    );
  },
  singleAsset: (data) => {
    const textSubsections = parseText(data.description?.items);
    return (
      <div className={styles.takeawaysSubsection}>
        <TakeawaysSingleAsset data={data} text={textSubsections} />
      </div>
    );
  },
  text: (data) => {
    const textSubsections = parseText(data.description?.items);
    return (
      <div className={styles.takeawaysSubsection}>
        {parseSections(textSubsections)}
      </div>
    );
  },
};
/* eslint-enable react/display-name */

const parseText = (data) => {
  return data?.map((item) => {
    return {
      ...item,
      type: 'text',
    };
  });
};

const TakeawaysSubsection = (data) => {
  if (!data) return null;

  const { type } = data;
  if (!Object.keys(sectionTypes).includes(type)) {
    return null;
  }

  return sectionTypes[type](data);
};

export default TakeawaysSubsection;
