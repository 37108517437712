import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

const Button = (data) => {
  if (!data) return null;

  const { className, children, handleClick } = data;

  return (
    // className must go after data spread, otherwise it'll be overwritten by data.className
    <button
      {...data}
      className={cx(styles.button, className)}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

Button.displayName = 'Button';

export default Button;
