import React from 'react';
import LazyLoad from 'react-lazyload';
import cx from 'classnames';

import BREAKPOINTS from '~constants/breakpoints';
import { createSrcSet } from '~utils/resizeImage';

import styles from './Image.module.scss';

const OFFSET_TOP = 1000;

const renderSources = (srcSet) => {
  return BREAKPOINTS.map((bp, index) => {
    return (
      srcSet[bp.name] && (
        <source
          key={`${srcSet[bp.name]}-image-${index}`}
          media={bp.media}
          srcSet={srcSet[bp.name]}
        />
      )
    );
  }).reverse();
};

const getSrcSet = (image, srcSet, sizeSet) => {
  if (srcSet) {
    return srcSet;
  }

  return sizeSet
    ? createSrcSet(image.uri, sizeSet)
    : {
        sm: image.uri,
        md: image.uri,
        lg: image.uri,
        xl: image.uri,
      };
};

const Image = (props) => {
  const {
    image,
    className,
    ratio,
    srcSet,
    title,
    inlineImage,
    isSizedByWidth,
    hasShadow,
    longImageDescription,
  } = props;

  if (!image) {
    return null;
  }

  let sizeSet = props.sizeSet;
  const sizeByWidth =
    typeof isSizedByWidth !== 'undefined'
      ? isSizedByWidth
      : image.type && (image.type === 'svg' || image.type === 'gif');
  const imageWrapperClasses = cx(styles.wrapper, {
    [styles.isInlineImage]: inlineImage,
    [styles.isSizedByWidth]: sizeByWidth,
    [styles.hasShadow]: hasShadow,
    [`${className}`]: className,
  });
  const imageClasses = cx(styles.container, {
    [styles.containerIsInlineImage]: inlineImage,
  });
  const imageRatio = `${ratio === 0 ? 100 : 100 / ratio}%`;
  const paddingTop = inlineImage || sizeByWidth ? 0 : imageRatio;

  if (image.type && (image.type === 'svg' || image.type === 'gif')) {
    sizeSet = undefined;
  }

  const sourceSet = getSrcSet(image, srcSet, sizeSet);

  return (
    <div className={imageWrapperClasses} style={{ paddingTop }}>
      <LazyLoad
        offset={OFFSET_TOP}
        placeholder={<div className={styles.placeholder} />}
      >
        {longImageDescription ? (
          <figure role='group'>
            <picture className={styles.picture}>
              {renderSources(sourceSet)}
              <img
                alt={image.alt || ''}
                className={imageClasses}
                src={image.uri}
                title={title}
                {...(image.type === 'svg' ? { role: 'img' } : {})} // svg image requires explicit role for assistive tech
                {...props}
              />
            </picture>
            <figcaption className={styles.visuallyHidden}>
              <span>{longImageDescription}</span>
            </figcaption>
          </figure>
        ) : (
          <picture className={styles.picture}>
            {renderSources(sourceSet)}
            <img
              alt={image.alt || ''}
              className={imageClasses}
              src={image.uri}
              title={title}
              {...(image.type === 'svg' ? { role: 'img' } : {})} // svg image requires explicit role for assistive tech
              {...props}
            />
          </picture>
        )}
      </LazyLoad>
    </div>
  );
};

Image.defaultProps = {
  ratio: 16 / 9,
  title: '',
};

Image.displayName = 'Image';

export default Image;
