import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import isNil from 'lodash/isNil';

export default (name) => {
  const id = `form-${name}`;
  const form = useFormContext();
  const field = useWatch({ name });
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(!isNil(form.errors[name]));
  }, [form, name]);

  return {
    id,
    form,
    field,
    hasError,
  };
};
