import React, { useMemo } from 'react';
import shuffle from 'lodash/shuffle';

import ContentRollerController from '~components/Global/ContentRoller/ContentRollerController';
import SingleOutcome from '~components/Global/SingleOutcome';

import { nestArray } from '~utils/helpers';

import styles from './FactsList.module.scss';

const ITEMS_QTY = 2;

const FactsList = ({ data }) => {
  const facts = useMemo(
    () => (data ? nestArray(shuffle(data), ITEMS_QTY) : []),
    [data]
  );

  const factsWithAnimation = useMemo(
    () => (facts ? facts.filter((f) => f.length > 1) : []),
    [facts]
  );

  if (!data) return null;

  return (
    <ContentRollerController length={ITEMS_QTY}>
      {({ state, ref, isPlaying }) => (
        <ul ref={ref} className={styles.content}>
          {facts.map((item, i) => {
            return (
              <li
                className={styles.listItem}
                key={`${item[0].largeText[0].text}-${item[0].text}-${i}`}
              >
                <SingleOutcome
                  index={i}
                  topClassName={styles.singleOutcomeTop}
                  facts={item}
                  key={item.text}
                  shouldAnimate={
                    isPlaying
                      ? factsWithAnimation.findIndex(
                          (j) => j[0].text === item[0].text
                        ) === state
                      : null
                  }
                />
              </li>
            );
          })}
        </ul>
      )}
    </ContentRollerController>
  );
};

export default FactsList;
