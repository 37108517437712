import { useEffect, useState } from 'react';

// This hook can be used to get more information about scroll, like position.
const useScrollWatcher = () => {
  const [lastScroll, setLastScroll] = useState();

  const scrollHandler = () => {
    setLastScroll(new Date().getTime());
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);

    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  });

  return {
    lastScroll,
  };
};

export default useScrollWatcher;
