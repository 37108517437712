export const sendNewsletterEvent = () =>
  sendEvent('newsletter', {
    status: 'complete',
    newsletterId: new Date().getTime(),
  });

export const sendJobApplicationEvent = (role) =>
  sendEvent('application', {
    status: 'complete',
    openRole: role,
    openRoleId: new Date().getTime(),
  });

export const sendPageEvent = ({
  location,
  path,
  title,
  category,
  subcategory,
}) =>
  sendEvent('pageview', {
    pageLocation: location,
    pagePath: path,
    pageTitle: title,
    pageCategory: category,
    pageSubCategory: subcategory,
  });

export const sendEvent = (event, parameters = {}) => {
  if (!window) {
    return;
  }

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event,
    ...parameters,
  });
};
