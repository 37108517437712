import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import usePlayer from './usePlayer';

import styles from './Video.module.scss';

const Video = (props) => {
  const {
    color = '',
    className,
    videoClassName,
    hasControls = true,
    title,
    videoId,
    loop = true,
    autoplay = false,
    width,
    height,
    hasShadow = false,
    thumbnail,
    ratio = 16 / 9,
  } = props;
  const mediaClasses = cx(styles.wrapper, className, {
    [styles.wrapperHasShadow]: hasShadow,
  });
  const videoClasses = cx(styles.iframe, videoClassName);

  const actualRatio = width && height ? width / height : ratio;
  const paddingTop = `${actualRatio === 0 ? 100 : 100 / actualRatio}%`;
  const videoRef = useRef(null);
  const { wrapperRef, isMuted, toggleMuteVideo, loaded } = usePlayer({
    videoRef,
    autoplay,
  });
  const [iframeSource, setIframeSource] = useState(null);

  useEffect(() => {
    setIframeSource(
      `https://player.vimeo.com/video/${videoId}?color=${color}&autoplay=${
        autoplay ? 1 : 0
      }&loop=${loop ? 1 : 0}&api=1&muted=${
        isMuted ? 1 : 0
      }&texttrack=en&title=1&playsinline=1&controls=${hasControls ? 1 : 0}`
    );
  }, [videoId]);

  return (
    <div className={mediaClasses} style={{ paddingTop }} ref={wrapperRef}>
      {videoId && (
        <>
          {!loaded && <div className={styles.placeholder} />}
          {thumbnail && (
            <div
              className={styles.cover}
              style={{ backgroundImage: `url(${thumbnail})` }}
            />
          )}
          {hasControls && (
            <button
              className={styles.playButton}
              onClick={toggleMuteVideo}
              aria-label={
                isMuted
                  ? 'This video is muted, click to turn on audio'
                  : 'Mute this video'
              }
            >
              <span className={styles.playButtonIcon}>
                {isMuted ? 'Audio' : 'Mute'}
              </span>
            </button>
          )}
          <iframe
            key={`${videoId}-${hasControls}`}
            ref={videoRef}
            className={videoClasses}
            src={iframeSource}
            frameBorder='0'
            title={title}
            allow='autoplay; fullscreen'
            allowFullScreen
          ></iframe>
        </>
      )}
    </div>
  );
};

export default Video;
