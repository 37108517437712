import React, { useEffect } from 'react';
import cx from 'classnames';

import ContentRollerController from '~components/Global/ContentRoller/ContentRollerController';
import SingleOutcome from '~components/Global/SingleOutcome';

import { nestArray } from '~utils/helpers';
import useRouteInfo from '~hooks/useRouteInfo';

import styles from './CaseOutcomesSection.module.scss';

const MAX_ITEMS = 5;

const CaseOutcomesSection = ({ data, limitItems, isFullWidth, ...props }) => {
  const routeName = useRouteInfo().title;
  const testId = props.testId || `${routeName}-outcomes-module`;

  const facts =
    data?.items &&
    nestArray(
      limitItems ? data.items.slice(0, MAX_ITEMS) : data.items,
      MAX_ITEMS
    );

  useEffect(() => {
    const rowCount = Math.ceil(facts.length / 2);
    document.documentElement.style.setProperty(
      '--outcome-heading-row-span',
      `span ${rowCount}`
    );
  }, [facts]);

  if (!data?.items) {
    return null;
  }

  const animatedItems = facts.filter((i) => i.length > 1);

  return (
    <section
      data-test-id={testId}
      className={cx(styles.container, { [styles.fullWidth]: isFullWidth })}
    >
      <h2 className={styles.heading}>{data.label}</h2>
      <ContentRollerController length={animatedItems.length}>
        {({ state, isPlaying, ref }) =>
          facts &&
          facts.map((item, i) => (
            <div
              className={styles.singleOutcome}
              ref={ref}
              key={`${item[0].title || item[0].text}-${i}`}
            >
              <SingleOutcome
                index={i}
                topClassName={styles.singleOutcomeTop}
                facts={item}
                shouldAnimate={
                  isPlaying
                    ? animatedItems.findIndex(
                        (j) => j[0].text === item[0].text
                      ) === state
                    : null
                }
              />
            </div>
          ))
        }
      </ContentRollerController>
    </section>
  );
};

export default CaseOutcomesSection;
