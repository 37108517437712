const fetch = require(`node-fetch`);

const JOB_APPLICATION_ERROR = 'Something went wrong, try again later.';
const CONTACT_FORM_ERROR = 'Something went wrong, try again later.';

const API = {
  fetchData: async function (url, shouldThrow = false) {
    const endpoint = `${process.env.GATSBY_API_ENDPOINT}${url}`;
    const auth = `${process.env.GATSBY_API_USERNAME}:${process.env.GATSBY_API_PASSWORD}`;
    const encodedAuth = Buffer.from(auth).toString('base64');
    try {
      const response = await fetch(endpoint, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Basic ${encodedAuth}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (err) {
      if (shouldThrow) {
        throw err;
      } else {
        console.error(err);
        return;
      }
    }
  },
  subscribeToNewsletter: async function (data) {
    if (!data) return null;
    const payload = {
      email: data.email,
    };

    try {
      const response = await fetch(process.env.GATSBY_NEWSLETTER_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const res = await response.json();
      return res;
    } catch (err) {
      console.error(err);
      return;
    }
  },
  sendApplication: async function (data) {
    const formData = new FormData();

    if (!data) return null;
    Object.keys(data).forEach((i) => {
      if (!data[i]) {
        return;
      }

      return formData.append(
        i,
        typeof data[i] === 'object' && data[i].length ? data[i][0] : data[i]
      );
    });

    try {
      const response = await fetch(process.env.GATSBY_APPLICATION_ENDPOINT, {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        return {
          status: response.status,
          ok: response.ok,
        };
      }
      return {
        title: JOB_APPLICATION_ERROR,
      };
    } catch (err) {
      console.error(err);
      return {
        title: JOB_APPLICATION_ERROR,
      };
    }
  },
  sendContactForm: async function (data) {
    const formData = new FormData();

    if (!data) return null;
    Object.keys(data).forEach((i) => {
      if (!data[i]) {
        return;
      }

      if (Array.isArray(data[i])) {
        data[i].forEach((datum) => {
          formData.append(`${i}[]`, datum);
        });
        return;
      }

      return formData.append(
        i,
        typeof data[i] === 'object' && data[i].length ? data[i][0] : data[i]
      );
    });

    try {
      const response = await fetch(process.env.GATSBY_CONTACT_ENDPOINT, {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        return {
          status: response.status,
          ok: response.ok,
        };
      }
      return {
        title: CONTACT_FORM_ERROR,
      };
    } catch (err) {
      console.error(err);
      return {
        title: CONTACT_FORM_ERROR,
      };
    }
  },
};

module.exports = API;
