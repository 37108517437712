import React from 'react';
import cx from 'classnames';
import { useLocation } from '@reach/router';

import GridSection from '~components/Global/GridSection';
import Link from '~components/Global/Link';

import FactsList from './FactsList';

import styles from './CasesList.module.scss';

const CasesList = ({ data }) => {
  const location = useLocation();
  if (!data) return null;

  return (
    <div
      data-test-id='case-studies-module-container'
      className={styles.container}
    >
      {data.filter(Boolean).map((i) => {
        const slug = i.uri.replace(`${location.pathname}/`, '');
        return (
          <div
            data-test-id={`case-study-${slug}`}
            key={i.uri}
            className={styles.item}
          >
            <GridSection
              left={
                <div>
                  <h2 className={styles.heading}>{i.title}</h2>
                  <Link
                    aria-label={`See ${i.title} case study`}
                    data-test-id={`case-study-${slug}-link`}
                    className={cx(styles.link, styles.desktopLink)}
                    to={i.uri}
                  >
                    See case study
                  </Link>
                </div>
              }
              right={<FactsList data={i.facts} />}
            />
            <Link
              aria-label={`See ${i.title} case study`}
              data-test-id={`case-study-${slug}-link`}
              className={cx(styles.link, styles.mobileLink)}
              to={`${i.uri}`}
            >
              See case study
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default CasesList;
