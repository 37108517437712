import React, { useRef } from 'react';
import cx from 'classnames';

import TextColumn from '~components/Global/TextColumn';
import IntroductionCasesGrid from '~components/Global/IntroductionCasesGrid';
import Layout from '~components/Global/Layout';
import Logo from '~components/Global/Logo';
import LogoGrid from '~components/Global/LogoGrid';
import PageHeader from '~components/Global/PageHeader';
import GridSection from '~components/Global/GridSection';

import useBreakpoint from '~hooks/useBreakpoint';

import styles from './Introduction.module.scss';

/**
 * These values represent the top position of the logo
 * when the menu is opened.
 * See src/components/Global/Header/Header.module.scss
 * for style declarations.
 */
const openedMenuLogoTopPosition = {
  sm: 40,
  md: 70,
};

const Introduction = ({ data, location }) => {
  const breakpoint = useBreakpoint();
  const logoRef = useRef();

  const menuCallback = (isOpen) => {
    if (logoRef.current) {
      if (isOpen) {
        const boundingRect = logoRef.current.getBoundingClientRect();
        const logoTop = boundingRect.top;
        logoRef.current.style.transform = `translateY(${
          openedMenuLogoTopPosition[breakpoint] - logoTop
        }px)`;
      } else {
        logoRef.current.style.transform = `translateY(0px)`;
      }
    }
  };

  if (!data) return null;

  return (
    <>
      <Layout
        data={data}
        hideIndexLink
        hideLogo
        theme='black'
        location={location}
        menuCallback={menuCallback}
      >
        <Logo
          className={cx(styles.introLogo, styles.introLogoSticky)}
          isLink={false}
          ref={logoRef}
        />
        <div className={styles.container}>
          <PageHeader
            className={styles.header}
            headline={data.headline}
            label={
              <Logo
                className={cx(styles.introLogo, styles.introLogoRelative)}
                isLink={false}
              />
            }
            isLabelHeading={false}
            text={data.text}
            title='introduction'
          />
          {data?.cases && (
            <IntroductionCasesGrid
              className={styles.cases}
              cases={data.cases}
              caseLinkLabel={data.caseLinkTitle}
            />
          )}
          {data?.capabilities && (
            <TextColumn
              title={data.capabilities.title}
              items={data.capabilities.capabilities}
              link={data.capabilities.viewAllLink}
            />
          )}
          {data?.clients && (
            <LogoGrid
              title={data.clients.title}
              text={data.clients.text}
              logos={data.clients.logos}
            />
          )}
          <section className={styles.staticContainer}>
            <GridSection
              left={<h2 className={styles.staticHeading}>{'Who we are'}</h2>}
              right={
                <p className={styles.staticDescription}>
                  {
                    'AKDM is a diverse firm committed to equity and access. We participate in diversity and equity training to empower our stakeholders to speak and act with kindness and empathy towards one another. Our solidarity sessions ensure that we remain focused on making AKDM a place where all our employees feel a sense of belonging.'
                  }
                </p>
              }
            />
          </section>
        </div>
      </Layout>
    </>
  );
};

Introduction.displayName = 'Introduction';

export default Introduction;
