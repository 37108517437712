import { render } from 'react-dom';

const RENDER_CONTAINER_ID = '__REACT_RENDERED_SIZE_CONTAINER__';
const DEFAULT_CONTAINER_ELEMENT = 'div';

const getMainContainer = () => {
  if (typeof document === 'undefined') {
    return;
  }

  return document.getElementById(RENDER_CONTAINER_ID) || createMainContainer();
};

const createMainContainer = () => {
  if (typeof document === 'undefined') {
    return;
  }

  const container = document.createElement(DEFAULT_CONTAINER_ELEMENT);

  container.id = RENDER_CONTAINER_ID;
  container.style.left = '-10000px';
  container.style.position = 'absolute';
  container.style.top = '-10000px';
  container.style.visibility = 'hidden';
  document.body.appendChild(container);
  return container;
};

export default async (element, containerWidth) => {
  if (typeof document === 'undefined') {
    return;
  }
  const renderContainer = document.createElement(DEFAULT_CONTAINER_ELEMENT);
  const mainContainer = getMainContainer();

  if (!mainContainer) {
    return;
  }

  mainContainer.appendChild(renderContainer);
  renderContainer.style.width = `${containerWidth}px`;

  const size = await new Promise((resolve, reject) => {
    render(element, renderContainer, () => {
      const rendered = renderContainer.firstChild;
      if (rendered) {
        resolve({
          height: rendered.offsetHeight,
          width: rendered.offsetWidth,
        });
      } else {
        resolve({});
      }
    });
  });

  mainContainer.removeChild(renderContainer);

  return size;
};
