import { useLayoutEffect } from 'react';

const useLockBodyScroll = (shouldLockScroll) => {
  useLayoutEffect(() => {
    if (shouldLockScroll) {
      // Get original body overflow
      const originalStyle = window.getComputedStyle(document.body).overflow;
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle);
    }
  }, [shouldLockScroll]);
};

export default useLockBodyScroll;
