import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import FileField from './FileField';
import SelectField from './SelectField';

import Field from '~components/Global/Form/Field';
import Button from '~components/Global/Button';
import Loading from '~components/Global/Loading';

import useRouteInfo from '~hooks/useRouteInfo';

import styles from './Form.module.scss';

const isSubmitDisabled = (formState) => {
  const { isValid } = formState;
  return !isValid;
};

const Form = ({
  button,
  submit,
  className,
  fields,
  buttonClassName,
  fieldClassName,
  formId,
  mode = 'onSubmit',
}) => {
  const form = useForm({ mode, reValidateMode: 'onChange' });
  const { clearErrors, formState, handleSubmit } = form;
  const { title: routeName, parentRoute } = useRouteInfo();
  const [loading, setLoading] = useState(false);
  const isButtonDisabled = isSubmitDisabled(formState);
  const buttonTestId = () => {
    const active = !isButtonDisabled ? 'active' : 'inactive';
    return `${formId}-btn-${active}`;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await submit(data);
    setLoading(false);
    if (!response.status) {
      form.setError('form', {
        type: 'response',
        message: response.title,
      });
    }
    return response;
  };

  const handleChange = () => {
    if (!isNil(form?.errors)) {
      clearErrors(['form']);
    }
  };

  const buttonClasses = cx(styles.button, buttonClassName, {
    [styles.darkBg]: !isButtonDisabled,
  });

  return (
    <FormProvider {...form}>
      <Loading active={loading} />
      {fields && (
        <form
          className={cx(styles.form, className)}
          onSubmit={handleSubmit(onSubmit)}
          onChange={handleChange}
          data-test-id={`${formId || routeName}-form`}
        >
          {fields.map((field) => {
            switch (field.type) {
              case 'file':
                return (
                  <FileField
                    className={cx(styles.inputField, fieldClassName)}
                    {...field}
                    key={field.name}
                    testId={`${parentRoute || routeName}-${field.name}`}
                  />
                );
              case 'select':
                return (
                  <SelectField
                    className={cx(styles.field, fieldClassName)}
                    {...field}
                    key={field.name}
                    options={field.options}
                    testId={`${parentRoute || routeName}-${field.name}`}
                  />
                );
              default:
                return (
                  <Field
                    className={cx(styles.field, fieldClassName)}
                    {...field}
                    key={field.name}
                    testId={`${parentRoute || routeName}-${field.name}`}
                  />
                );
            }
          })}
          {button && (
            <Button
              className={buttonClasses}
              disabled={isButtonDisabled}
              data-test-id={buttonTestId()}
              type='submit'
            >
              {button.label}
            </Button>
          )}
          {form?.errors?.form && (
            <span className={cx(styles.message, styles.error)} role='alert'>
              {form.errors.form.message}
            </span>
          )}
        </form>
      )}
    </FormProvider>
  );
};

Form.displayName = 'Form';

export default Form;
