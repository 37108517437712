import React from 'react';

import styles from './ContentRollerText.module.scss';
import TextVerticalRoller from '../TextVerticalRoller';

const ContentRollerText = ({ text, className }) => {
  if (!text) return null;

  return (
    <TextVerticalRoller motionKey={text} className={styles.element}>
      <div className={className}>{text}</div>
    </TextVerticalRoller>
  );
};

ContentRollerText.displayName = 'ContentRollerText';

export default ContentRollerText;
