import React from 'react';

import Link from '~components/Global/Link';

import styles from './TextColumn.module.scss';

const TextColumn = ({ title, items, link }) => {
  return (
    <section className={styles.container}>
      <h2 className={styles.heading}>{title}</h2>
      <div className={styles.contentContainer}>
        {!!items && items.length ? (
          <div className={styles.columnContainer}>
            {items.map(({ title, items }) => (
              <React.Fragment key={title}>
                <h3 className={styles.columnHeading}>{title}</h3>
                <ul className={styles.columnList}>
                  {items.map((item) => (
                    <li key={item} className={styles.columnListItem}>
                      {item}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </div>
        ) : null}
        {link ? (
          <Link className={styles.viewAll} to={link}>
            View all
          </Link>
        ) : null}
      </div>
    </section>
  );
};

TextColumn.displayName = 'TextColumn';

export default TextColumn;
