import React from 'react';
import kebabCase from 'lodash/kebabCase';

import List from '~components/Global/List';
import Link from '~components/Global/Link';
import Markdown from '~components/Global/Markdown';

import styles from './DepartmentJobs.module.scss';

const ParagraphRender = (props) => (
  <p className={styles.paragraph}>{props.children}</p>
);

const DepartmentJobs = ({
  text,
  jobs,
  title: department,
  noOpenJobsMessage,
}) => {
  if (!text && !jobs) return null;

  const createListItem = (job) => {
    return (
      <Link
        className={styles.job}
        to={job.uri}
        data-test-id={`${kebabCase(department)}-roles-${kebabCase(job.title)}`}
      >
        <p className={styles.title}>{job.title}</p>
        <p className={styles.location}>{job.location}</p>
      </Link>
    );
  };

  return (
    <div className={styles.jobDetail}>
      {Boolean(jobs.length) && (
        <List
          className={styles.jobsList}
          createListItem={createListItem}
          items={jobs}
          listItemClassName={styles.listItem}
        />
      )}
      <Markdown
        renderersProps={{
          paragraph: ParagraphRender,
        }}
        source={text}
        className={styles.description}
      />
      {jobs.length === 0 && (
        <Markdown
          renderersProps={{
            paragraph: ParagraphRender,
          }}
          source={noOpenJobsMessage}
          className={styles.description}
        />
      )}
    </div>
  );
};

DepartmentJobs.displayName = 'DepartmentJobs';

export default DepartmentJobs;
