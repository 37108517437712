import React, { useEffect, useState } from 'react';
import ContentRollerText from './ContentRollerText';
import getRenderedSize from '~utils/getRenderedSize';

export default ({ texts, className, containerWidth }) => {
  const [textMinHeight, setTextMinHeight] = useState(0);

  useEffect(() => {
    if (!texts?.length) {
      return;
    }

    let minHeight = 0;
    Promise.all(
      texts.map(async (t) =>
        getRenderedSize(
          <ContentRollerText text={t} className={className} />,
          containerWidth
        )
      )
    ).then((dimensions) => {
      dimensions.forEach((d) => {
        if (d.height > minHeight) {
          minHeight = d.height;
        }
      });

      setTextMinHeight(minHeight);
    });
  }, [texts, containerWidth, className]);

  return textMinHeight;
};
