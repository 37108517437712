import React from 'react';

import { useSelector } from 'react-redux';

import ApplicationSuccess from '~components/Pages/ApplicationSuccess';
import Contact from '~components/Pages/Contact';
import Introduction from '~components/Pages/Introduction';
import News from '~components/Pages/News';
import Newsletter from '~components/Pages/Newsletter';
import Page from '~components/Pages/Page';

import { usePageData } from '~hooks/usePageData';

const Template = (context) => {
  const data = context?.pageContext;
  const currentData = usePageData(data, context.path);
  const uri =
    useSelector((state) => state.uri) || data.uri.replace('/live-preview', '');

  switch (true) {
    case uri === '/':
      return <Introduction data={currentData} location={context?.location} />;
    case uri === '/newsletter':
      return <Newsletter data={currentData} location={context?.location} />;
    case uri.includes('/news/'):
      return <News data={currentData} location={context?.location} />;
    case uri === '/careers/success':
      return (
        <ApplicationSuccess data={currentData} location={context?.location} />
      );
    case uri === '/contact':
      return <Contact data={currentData} location={context?.location} />;
    default:
      return <Page data={currentData} location={context?.location} />;
  }
};

Template.displayName = 'Template';

export default Template;
