import React from 'react';
import cx from 'classnames';

import Media from '~components/Global/Media';

import styles from './TakeawaysSingleAsset.module.scss';

const TakeawaysSingleAsset = ({ data }) => {
  if (!data) return null;

  const { mediaPosition } = data;
  const classes = cx({
    [styles.singleAsset]: true,
    [styles[`${mediaPosition}`]]: `${mediaPosition}`,
  });

  const getText = (description) =>
    description.map((item) => (
      <React.Fragment key={item.title}>
        <p className={styles.title}>{item.title}</p>
        <p className={styles.paragraph}>{item.paragraph}</p>
      </React.Fragment>
    ));

  return (
    <div className={classes}>
      <div className={styles.image}>{data.asset && <Media data={data} />}</div>
      <div className={styles.description}>
        {data.description?.items && getText(data.description?.items)}
      </div>
    </div>
  );
};

export default TakeawaysSingleAsset;
