import React, { useEffect, useState } from 'react';

import List from '~components/Global/List';
import styles from './ListInfinite.module.scss';

const ListInfinite = (data) => {
  const {
    ariaLabel,
    handleMore,
    allItems,
    listItemClassName,
    step = 5,
    moreButtonTestId,
    ...props
  } = data;
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState();

  useEffect(() => {
    setTotal(allItems ? allItems.length : 0);
  }, [allItems]);

  useEffect(() => {
    setItems(allItems ? allItems.slice(0, step) : []);
  }, [allItems, step]);

  if (!allItems) return null;

  const handleClick = async () => {
    setItems(allItems.slice(0, items.length + step));
  };

  const showMoreButton = () => {
    return allItems?.length > step && items.length < total;
  };

  return (
    <>
      {items && (
        <>
          <List
            {...props}
            listItemClassName={listItemClassName}
            items={items}
          />

          {showMoreButton() && (
            <div className={styles.buttonWrapper}>
              <button
                aria-label={ariaLabel}
                className={styles.button}
                onClick={handleClick}
                data-test-id={moreButtonTestId}
              >
                More
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

ListInfinite.displayName = 'ListInfinite';

export default ListInfinite;
