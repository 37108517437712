import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './ContentRoller.module.scss';
import ContentRollerSupport from './ContentRollerSupport';
import ContentRollerText from './ContentRollerText';
import useWindowResize from '~hooks/useWindowResize';
import getRenderedSize from '~utils/getRenderedSize';

const ContentRoller = ({
  text,
  support,
  supportPosition,
  className,
  height,
  width,
  elementClassName,
  supportClassName,
}) => {
  const [textWidth, setTextWidth] = useState();
  const { width: windowWidth } = useWindowResize();

  useEffect(() => {
    if (!text) {
      return;
    }

    getRenderedSize(
      <ContentRollerText text={text} className={elementClassName} />,
      width
    ).then((dimension) => setTextWidth(dimension.width));
  }, [text, setTextWidth, windowWidth, elementClassName]);

  return (
    <div
      style={{
        height,
        width,
      }}
      className={cx(className, styles.container)}
    >
      <ContentRollerText text={text} className={elementClassName} />
      <ContentRollerSupport
        text={text}
        containerWidth={width}
        support={support}
        textWidth={textWidth}
        textPosition={supportPosition}
        supportClassName={supportClassName}
      />
    </div>
  );
};

ContentRoller.displayName = 'ContentRoller';

export default ContentRoller;
