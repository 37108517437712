import React from 'react';
import cx from 'classnames';

import Image from '~components/Global/Image';
import Video from '~components/Global/Video';

import styles from './Media.module.scss';

const GalleryItemSize = Object.freeze({
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
});

const GALLERY_CONFIG = Object.freeze({
  [GalleryItemSize.Small]: {
    ratio: 14 / 9,
    sizeSet: {
      sm: [246, 158],
      md: [458, 294],
      lg: [652, 419],
      xl: [940, 617],
    },
  },
  [GalleryItemSize.Medium]: {
    ratio: 3 / 4,
    sizeSet: {
      sm: [246, 328],
      md: [458, 611],
      lg: [653, 870],
      xl: [1030, 1373],
    },
  },
  [GalleryItemSize.Large]: {
    ratio: 16 / 9,
    sizeSet: {
      sm: [506, 285],
      md: [821, 462],
      lg: [1325, 745],
      xl: [2080, 1170],
    },
  },
});

/* eslint-disable react/display-name */
const galleryItemType = {
  image: ({ image, type }) => <ImageAsset data={image} type={type} />,
  video: ({ video }) => <VideoAsset data={video} />,
};
/* eslint-enable react/display-name */

const ImageAsset = ({ data }) => {
  const image = Object.prototype.hasOwnProperty.call(data, 'image')
    ? data.image
    : data;
  const { width, height } = image || {};
  let sizeSet;
  let ratio;

  if (Object.values(GalleryItemSize).includes(data.type)) {
    sizeSet = GALLERY_CONFIG[data.type].sizeSet;
    ratio = GALLERY_CONFIG[data.type].ratio;
  } else if (width && height) {
    // Respect image aspect ratio when you don't have the gallery size
    ratio = width / height;
  }

  return (
    <Image
      image={image}
      isSizedByWidth={false}
      ratio={ratio}
      sizeSet={sizeSet}
    />
  );
};

const VideoAsset = ({ data }) => {
  const { ratio } = GALLERY_CONFIG[data.type] || data.width / data.height;
  return <Video {...{ ...data, ratio, title: data.videoTitle }} />;
};

const Media = ({ className, data, type }) => {
  if (!data) return null;

  let mediaData = data.asset || data;

  if (type === 'hero') {
    mediaData.image = { ...mediaData.image, type: 'large' };
  }

  return (
    <div className={cx(styles.media, className)}>
      {galleryItemType[mediaData.type](mediaData)}
    </div>
  );
};

Media.displayName = 'Media';

export default Media;
