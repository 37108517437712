import React from 'react';

import GridSection from '~components/Global/GridSection';

import styles from './TakeawaysIndex.module.scss';

const TakeawaysIndex = (props) => {
  const { title, sections, testId } = props;

  const handleClick = (index) => {
    const takeaway = document.querySelector(`#takeaway-${index}`);
    if (!takeaway) {
      return;
    }

    takeaway.focus({ preventScroll: true });
    takeaway.scrollIntoView({ behavior: 'smooth' });
  };

  const getIndex = (sections) => {
    const listItems = sections?.map((section, index) => (
      <li className={styles.indexItem} key={`takeaway-${index}`}>
        <button className={styles.indexLink} onClick={() => handleClick(index)}>
          <span>
            {/* whitespace is used here because CSS propreties cause an underline break betwee the number and the text */}
            {(index + 1).toString().padStart(2, '0')}.&nbsp;&nbsp;&nbsp;
            {section?.linkTitle}
          </span>
        </button>
      </li>
    ));
    return <ol className={styles.indexList}>{listItems}</ol>;
  };

  return (
    <>
      {props && (
        <section data-test-id={testId} className={styles.section}>
          <GridSection left={title} right={getIndex(sections)} />
        </section>
      )}
    </>
  );
};

TakeawaysIndex.displayName = 'TakeawaysIndex';

export default TakeawaysIndex;
