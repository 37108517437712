import { useEffect } from 'react';
import { useLocation } from '@reach/router';

import { sendPageEvent } from '~utils/analytics';
import useRouteInfo from '~hooks/useRouteInfo';

export default (title) => {
  const location = useLocation();
  const info = useRouteInfo();
  useEffect(() => {
    if (info.title === 'success') {
      return;
    }

    let subcategory = info.parentRoute ? info.title : info.parentRoute;

    if (info.parentRoute === 'news') {
      subcategory = 'post';
    }

    sendPageEvent({
      location: location.href,
      path: location.pathname,
      title: title || info.title,
      category:
        info.title === 'introduction' ? 'home' : info.parentRoute || info.title,
      subcategory: subcategory,
    });
  }, []);
};
