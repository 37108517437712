import ReactGA from 'react-ga';
import get from 'lodash/get';

import { isPreviewMode } from './environments';

export const trackPageView = (page) => {
  if (!isPreviewMode) {
    ReactGA.pageview(page);
  }
};

export const formSubmission = (formName) => {
  ReactGA.event({
    category: 'Submission',
    action: `${formName} Form Submission`,
  });
};

export const navItemClicked = (page, label, route) => {
  ReactGA.event({
    category: 'Navigation',
    action: `${page} Navigation List Click`,
    label: `${label} (${route})`,
  });
};

export const homepageLogoClick = () => {
  ReactGA.event({
    category: 'Navigation',
    action: 'Homepage Logo Click',
  });
};

export const logEmailEvent = (email) => {
  const name = email.substring(0, email.lastIndexOf('@'));
  if (email) {
    ReactGA.event({
      category: 'Email',
      action: 'Click',
      label: name,
    });
  }
};

export const outboundLinkClick = (linkChildren, url) => {
  const label = get(linkChildren, '[0].props.value', '');
  ReactGA.event({
    category: 'Outbound Link',
    action: 'Click',
    label: `${label} (${url})`,
  });
};

export const officeAddressClick = (officeName) => {
  ReactGA.event({
    category: 'Office Address',
    action: 'Click',
    label: officeName,
  });
};

export const eventAddressClick = (eventLocationName) => {
  ReactGA.event({
    category: 'Event Address',
    action: 'Click',
    label: eventLocationName,
  });
};

export const pageNotFound = (pageUrlRequested) => {
  ReactGA.event({
    category: '404 Error - Page Not Found',
    action: `Searching for ${pageUrlRequested}`,
    label: pageUrlRequested,
  });
};
