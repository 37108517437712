import React, { useRef, useState } from 'react';
import cx from 'classnames';

import Popover from '~components/Global/Popover';

import useFormField from '~hooks/useFormField';
import { useOutsideClick } from '~hooks/useOutsideClick';

import ArrowDown from '~assets/svg/ArrowDown.svg';

import styles from './SelectField.module.scss';

const KeyCode = {
  DOWN: 40,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  TAB: 9,
  UP: 38,
};

const shiftIndex = (currIndex, diff, length) => {
  if (currIndex + diff < 0) {
    return length + diff;
  } else {
    return (currIndex + diff) % length;
  }
};

const SelectField = ({ className, label, name, options, validation }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const listboxRef = useRef(null);
  const optionRefs = useRef([]);
  const [activeDescendantIndex, setActiveDescendantIndex] = useState(null);

  const { id, form, hasError } = useFormField(name);

  const focusOptions = false;

  const inputClasses = cx(className, {
    [styles.field]: true,
    [styles.error]: hasError,
    [styles.rise]: isOpen || selectedValues.length > 0,
  });

  const getButtonLabel = (defaultLabel, values) => {
    if (values.length === 0) {
      return defaultLabel;
    }

    return values.length > 1
      ? `${values[0]}, +${values.length - 1}`
      : values[0];
  };

  const shiftActiveDescendant = (diff) => {
    let newIndex = 0;
    if (activeDescendantIndex !== null) {
      newIndex = shiftIndex(activeDescendantIndex, diff, options.length);
    }
    setActiveDescendantIndex(newIndex);
    return newIndex;
  };

  const focusNext = () => {
    const index = shiftActiveDescendant(1);
    optionRefs.current[index].focus();
  };

  const focusPrevious = () => {
    const index = shiftActiveDescendant(-1);
    optionRefs.current[index].focus();
  };

  const openListbox = (setDescendant) => {
    setIsOpen(true);
    const firstActiveIndex = options.findIndex((option) =>
      selectedValues.includes(option)
    );
    const focusedIndex = firstActiveIndex > 0 ? firstActiveIndex : 0;
    setDescendant && setActiveDescendantIndex(focusedIndex);

    listboxRef.current.focus();
  };

  const closeListbox = (setFocus) => {
    setIsOpen(false);
    setActiveDescendantIndex(null);
    setFocus && buttonRef.current.focus();
  };

  useOutsideClick(containerRef, () => {
    closeListbox();
  });

  const toggleSelection = (index, shouldClose) => {
    const optionValue = options[index];
    let newValues = [];

    if (selectedValues && selectedValues.includes(optionValue)) {
      newValues = selectedValues.filter((value) => value !== optionValue);
    } else {
      newValues = selectedValues
        ? [...selectedValues, optionValue]
        : [optionValue];
    }

    setSelectedValues(newValues);
    shouldClose && closeListbox(true);
  };

  const buttonEvents = {
    [KeyCode.DOWN]: () => {
      openListbox(true);
    },
    [KeyCode.UP]: () => {
      openListbox(true);
    },
  };

  const handleOnButtonKeyDown = (e) => {
    const { keyCode } = e;

    if (buttonEvents[keyCode]) {
      if (keyCode === KeyCode.UP || keyCode === KeyCode.DOWN) {
        e.preventDefault(); // prevent page scroll on up/down arrow keys
      }
      buttonEvents[keyCode]();
    }
  };

  const listEvents = {
    [KeyCode.DOWN]: () => {
      focusOptions ? focusNext() : shiftActiveDescendant(1);
    },
    [KeyCode.ESCAPE]: () => closeListbox(true),
    [KeyCode.TAB]: (e) => {
      if (e.shiftKey) {
        closeListbox();
      }
    },
    [KeyCode.ENTER]: () => {
      activeDescendantIndex !== null &&
        toggleSelection(activeDescendantIndex, true);
    },
    [KeyCode.SPACE]: () => {
      activeDescendantIndex !== null && toggleSelection(activeDescendantIndex);
    },
    [KeyCode.UP]: () => {
      focusOptions ? focusPrevious() : shiftActiveDescendant(-1);
    },
  };

  const handleOnListKeyDown = (e) => {
    const { keyCode } = e;

    if (listEvents[keyCode]) {
      if (
        keyCode === KeyCode.UP ||
        keyCode === KeyCode.DOWN ||
        keyCode === KeyCode.SPACE ||
        keyCode === KeyCode.ENTER
      ) {
        e.preventDefault(); // prevent page scroll on up/down arrow keys
      }
      listEvents[keyCode](e);
    }
  };

  const confirmEvents = {
    [KeyCode.TAB]: (e) => {
      if (e.shiftKey) {
        e.preventDefault();
        listboxRef.current.focus();
      } else {
        closeListbox();
      }
    },
  };

  const handleOnConfirmKeyDown = (e) => {
    const { keyCode } = e;

    if (confirmEvents[keyCode]) {
      if (
        keyCode === KeyCode.UP ||
        keyCode === KeyCode.DOWN ||
        keyCode === KeyCode.SPACE ||
        keyCode === KeyCode.ENTER
      ) {
        e.preventDefault(); // prevent page scroll on up/down arrow keys
      }
      confirmEvents[keyCode](e);
    }
  };

  const buttonLabel = getButtonLabel('', selectedValues, options);

  return (
    <div className={inputClasses} ref={containerRef}>
      <input
        type='hidden'
        name={name}
        value={selectedValues.join(',')}
        ref={form.register}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
        {validation.required && ' *'}
      </label>
      <Popover
        className={styles.popoverContainer}
        isOpen={isOpen}
        renderButton={() => (
          <button
            aria-label={label}
            id={id}
            className={styles.button}
            type='button'
            onClick={(event) => {
              event.preventDefault();
              if (!isOpen) {
                openListbox(true);
              } else {
                closeListbox();
              }
            }}
            onKeyDown={handleOnButtonKeyDown}
            ref={buttonRef}
          >
            {buttonLabel}
            <ArrowDown
              className={cx(styles.caret, { [styles.caretUp]: isOpen })}
            />
          </button>
        )}
        renderContent={() => (
          <div className={styles.listbox}>
            <ul
              aria-labelledby={name}
              className={styles.optionList}
              ref={listboxRef}
              role='listbox'
              aria-multiselectable='true'
              aria-activedescendant={
                typeof activeDescendantIndex !== 'undefined'
                  ? options[activeDescendantIndex]
                  : undefined
              }
              tabIndex={isOpen ? 0 : -1}
              onKeyDown={handleOnListKeyDown}
            >
              {options.map((option, index) => {
                const isSelected =
                  selectedValues && selectedValues.includes(option);
                return (
                  <li
                    key={option}
                    className={cx(styles.option, {
                      [styles.active]: activeDescendantIndex === index,
                    })}
                    role='option'
                    aria-selected={isSelected ? 'true' : 'false'}
                    onClick={() => {
                      toggleSelection(index);
                    }}
                  >
                    <span
                      aria-hidden='true'
                      className={cx(styles.optionCheck, {
                        [styles.selected]: isSelected,
                      })}
                    ></span>
                    {option}
                  </li>
                );
              })}
            </ul>
            <button
              className={styles.confirmButton}
              type='button'
              onClick={(event) => {
                event.preventDefault();
                closeListbox();
              }}
              onKeyDown={handleOnConfirmKeyDown}
              tabIndex={isOpen ? null : -1}
            >
              <span>Confirm</span>
            </button>
          </div>
        )}
      />
      {hasError && form.errors[name] && (
        <span className={cx(styles.message, styles.error)} role='alert'>
          {form?.errors[name].message}
        </span>
      )}
    </div>
  );
};

SelectField.displayName = 'SelectField';

export default SelectField;
