import React, { forwardRef } from 'react';
import cx from 'classnames';

import styles from './GridSection.module.scss';

const GridSection = forwardRef(
  ({ className, id, left, right, titleClassName, ...props }, ref) => {
    if (!right) return;

    return (
      <section
        id={id}
        ref={ref}
        className={cx(styles.GridSection, className)}
        {...props}
      >
        {Boolean(left) && (
          <>
            {typeof left === 'string' ? (
              <h2 className={cx(styles.GridSectionLeft, titleClassName)}>
                {left}
              </h2>
            ) : (
              <div className={cx(styles.GridSectionLeft, titleClassName)}>
                {left}
              </div>
            )}
          </>
        )}
        <div className={styles.GridSectionRight}>{right}</div>
      </section>
    );
  }
);

GridSection.displayName = 'GridSection';

export default GridSection;
