import { useCallback, useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';

import useIsInView from '~hooks/useIsInView';

export default ({ videoRef, autoplay }) => {
  const player = useRef(null);
  const { isInView, ref: wrapperRef } = useIsInView();
  const [isPlaying, setIsPlaying] = useState();
  const [loaded, setLoaded] = useState();
  const [isMuted, setIsMuted] = useState(autoplay);

  const onPlay = useCallback(() => {
    setIsPlaying(true);

    if (!loaded) {
      setLoaded(true);
    }
  }, [setIsPlaying, setLoaded, loaded]);

  const toggleMuteVideo = () => {
    player.current.ready().then(() => {
      player.current.setVolume(isMuted ? 1 : 0);
    });
  };

  useEffect(() => {
    if (!isInView && isPlaying) {
      setIsPlaying(false);
    } else if (isInView && !isPlaying && autoplay) {
      setIsPlaying(true);
    }
  }, [isInView, isPlaying, autoplay]);

  useEffect(() => {
    if (!player.current || !loaded) {
      return;
    }

    if (isPlaying) {
      player.current.play();
    } else {
      player.current.pause();
    }
  }, [loaded, isPlaying, player]);

  useEffect(() => {
    if (videoRef?.current?.hasAttribute('src')) {
      player.current = new Player(videoRef.current);

      player.current.off('pause');
      player.current.off('play');
      player.current.off('volumechange');
      player.current.on('play', onPlay);
      player.current.on('pause', () => {
        setIsPlaying(false);
      });
      player.current.on('volumechange', (data) => {
        if ((data.volume > 0 && isMuted) || (data.volume === 0 && !isMuted)) {
          setIsMuted((state) => !state);
        }
      });
    }

    return () => {
      if (player.current) {
        player.current.off('pause');
        player.current.off('play');
        player.current.off('volumechange');
      }
    };
  }, [videoRef, wrapperRef, setIsPlaying, setIsMuted, isMuted, onPlay]);

  return { wrapperRef, player, isMuted, toggleMuteVideo, loaded, setLoaded };
};
