import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import styles from './LinkGrid.module.scss';

const LinkGrid = ({ title, links }) => {
  return (
    <section className={styles.container}>
      <h2 className={styles.heading}>{title}</h2>
      {links && links.length ? (
        <ul
          className={cx(styles.list, {
            [styles.listEven]: links.length % 2 === 0,
            [styles.listOdd]: links.length % 2 !== 0,
          })}
        >
          {links.map(({ title, uri, isCurrentCaseStudy }) => (
            <li key={`${title}-${uri}`} className={styles.listItem}>
              {isCurrentCaseStudy ? (
                <span className={styles.currentCaseStudy}>{title}</span>
              ) : (
                <Link className={styles.link} to={uri}>
                  {title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </section>
  );
};

LinkGrid.displayName = 'LinkGrid';

export default LinkGrid;
