import React from 'react';
import kebabCase from 'lodash/kebabCase';

import styles from './OpenPositions.module.scss';
import GridSection from '../../Global/GridSection';
import Accordion from '../../Global/Accordion';
import List from '../../Global/List';
import DepartmentJobs from './DepartmentJobs';

const OpenPositions = ({ data }) => {
  if (!data) return null;

  const createListItem = (item) => (
    <Accordion
      title={item.title}
      accordionId={`${kebabCase(item.title)}-open-roles`}
    >
      <DepartmentJobs {...item} />
    </Accordion>
  );

  return (
    <GridSection
      className={styles.grid}
      left='Open Roles'
      data-test-id='open-roles-module'
      right={
        <List
          createListItem={createListItem}
          items={data}
          listItemClassName={styles.roleItem}
        />
      }
    />
  );
};

OpenPositions.displayName = 'OpenPositions';

export default OpenPositions;
