import React from 'react';

import GridSection from '~components/Global/GridSection';
import Image from '~components/Global/Image';

import styles from './LogoGrid.module.scss';

const LogoGrid = ({ title, text, logos }) => {
  return (
    <section className={styles.container}>
      <GridSection
        left={<h2 className={styles.heading}>{title}</h2>}
        right={<p className={styles.description}>{text}</p>}
      />
      {!!logos && logos.length ? (
        <ul className={styles.imageGrid}>
          {logos.map((logo) => (
            <li key={`${logo.uri}-${logo.alt}`}>
              <Image
                image={logo}
                ratio={340 / 126}
                sizeSet={{
                  sm: [340, 126],
                  md: [340, 126],
                  lg: [429, 159],
                  xl: [680, 252],
                }}
              />
            </li>
          ))}
        </ul>
      ) : null}
    </section>
  );
};

LogoGrid.displayName = 'LogoGrid';

export default LogoGrid;
