import React from 'react';
import cx from 'classnames';

import Hero from '~components/Global/Hero';
import Layout from '~components/Global/Layout';
import LogoGrid from '~components/Global/LogoGrid';
import LinkGrid from '~components/Global/LinkGrid';
import PageHeader from '~components/Global/PageHeader';

import useRouteInfo from '~hooks/useRouteInfo';
import parseComponents from '~utils/parseComponents';
import parseSections from '~utils/parseSections';

import styles from './Page.module.scss';

const Page = ({ data, location, theme = 'white' }) => {
  const route = useRouteInfo();
  const title = route.title || '404';

  return (
    <>
      {data && (
        <Layout data={data} hideIndexLink theme={theme} location={location}>
          <PageHeader
            headline={data.headline}
            label={data.label || 'AKDM'}
            text={data.text}
            title={title}
          />
          <div
            className={cx(styles.container, styles.content)}
            jest-id='content'
          >
            {(data?.heroImage || data?.heroVideo) && (
              <Hero heroImage={data.heroImage} heroVideo={data.heroVideo} />
            )}
            {data?.components && parseComponents(data.components)}
            {data?.sections && parseSections(data.sections)}
            {data?.clients && (
              <LogoGrid
                title={data.clients.title}
                text={data.clients.text}
                logos={data.clients.logos}
              />
            )}
            {data?.moreCaseStudies && (
              <LinkGrid
                title={data.moreCaseStudies.title}
                links={data.moreCaseStudies.caseStudies}
              />
            )}
          </div>
        </Layout>
      )}
    </>
  );
};

Page.displayName = 'Page';

export default Page;
