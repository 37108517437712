import React from 'react';
import kebabCase from 'lodash/kebabCase';
import cx from 'classnames';

import ContentSection from '~components/Global/ContentSection';
import GridSection from '~components/Global/GridSection';
import ListInfinite from '~components/Global/ListInfinite';
import Layout from '~components/Global/Layout';
import Link from '~components/Global/Link';
import Media from '~components/Global/Media';
import PageHeader from '~components/Global/PageHeader';
import Subscribe from '~components/Global/Subscribe';

import styles from './News.module.scss';

const News = ({ data, location }) => {
  if (!data) return null;
  const slug = kebabCase(data.headline);

  const createNewsItem = (news) => {
    if (!news) return null;

    const testId = news.headline.replace(/ /g, '-').toLowerCase();
    return (
      <Link
        className={styles.newsLink}
        to={news.uri}
        data-test-id={`news-${testId}`}
      >
        {news.headline}
      </Link>
    );
  };

  return (
    <>
      {data && (
        <Layout data={data} hideIndexLink location={location}>
          <PageHeader
            label={data.label}
            headline={data.headline}
            text={data.date}
            title={slug}
          />
          {data.media && (
            <div className={styles.media}>
              <Media data={data.media} />
            </div>
          )}
          <div className={styles.container}>
            {Boolean(data.text) && (
              <ContentSection
                className={styles.section}
                testId={`${slug}-introduction-module`}
                text={data.text}
              />
            )}
            {Boolean(data.newsletter) && (
              <GridSection
                className={cx(styles.section, styles.newsletter)}
                left={data.newsletter.title}
                right={
                  <Subscribe disclaimerText={data.newsletter.disclaimerText} />
                }
              />
            )}
            {Boolean(data.news?.length) && (
              <GridSection
                className={styles.section}
                left='More from AKDM'
                right={
                  <ListInfinite
                    ariaLabel='Load more news'
                    allItems={data.news}
                    listItemClassName={styles.newsItem}
                    createListItem={createNewsItem}
                    moreButtonTestId='news-more-news-link'
                    step={5}
                  />
                }
              />
            )}
          </div>
        </Layout>
      )}
    </>
  );
};

News.displayName = 'News';

export default News;
