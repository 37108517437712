import { useCallback, useEffect, useRef, useState } from 'react';
import { useReducedMotion } from 'framer-motion';

import useIndex from '../useIndex';

export default ({ initial = 0, length } = {}) => {
  const isReducedMotion = useReducedMotion();
  const currentInterval = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { state, next, setIndex } = useIndex({ length, initial });

  const startInterval = useCallback(() => {
    if (currentInterval.current || isPlaying) {
      return;
    }

    next();
    const interval = setInterval(() => {
      next();
    }, 5000);
    currentInterval.current = interval;
  }, [isPlaying, next]);

  const start = useCallback(() => {
    if (isPlaying || isReducedMotion) {
      return;
    }

    setIsPlaying(true);
    startInterval();
  }, [isPlaying, isReducedMotion, setIsPlaying, startInterval]);

  const clear = useCallback(() => {
    clearInterval(currentInterval.current);
    currentInterval.current = null;
    setIsPlaying(false);
  }, []);

  const pause = useCallback(() => {
    clear();
  }, [clear]);

  const resume = useCallback(() => {
    start();
  }, [start]);

  const restart = useCallback(() => {
    setIndex(0);
    start();
  }, [start, setIndex]);

  useEffect(() => {
    if (isReducedMotion) {
      clear();
    }

    return () => {
      clearInterval(currentInterval.current);
    };
  }, [clear, isReducedMotion]);

  return {
    state,
    resume,
    pause,
    restart,
    isPlaying,
  };
};
