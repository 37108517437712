import React from 'react';
import cx from 'classnames';

import Footer from '~components/Global/Footer';
import Header from '~components/Global/Header';
import SEO from '~components/Global/SEO';

import useRouteInfo from '~hooks/useRouteInfo';
import useTheme from '~hooks/useTheme';
import usePageDataLayerEvent from '~hooks/usePageDataLayerEvent';

import styles from './Layout.module.scss';

const Layout = ({
  className,
  children,
  data,
  theme = 'white',
  hideIndexLink = false,
  hideLogo = false,
  header = true,
  location,
  menuCallback,
}) => {
  const route = useRouteInfo();
  useTheme(theme);
  usePageDataLayerEvent(data?.metaData?.page?.pageTitle);

  return (
    <>
      {Boolean(data?.metaData) && <SEO metadata={data.metaData} />}
      <main className={cx(styles.main, className)}>
        {header && (
          <Header
            hideLogo={hideLogo}
            hideIndexLink={hideIndexLink}
            location={location}
            menuCallback={menuCallback}
          />
        )}
        <div className={styles[route.title]}>
          {Boolean(children) && children}
        </div>
        <Footer className={styles.footer} data={data?.footer} />
      </main>
    </>
  );
};

Layout.displayName = 'Layout';

export default Layout;
