import { useEffect, useState } from 'react';

/**
 * data-theme is also set in gatsby-ssr.js to
 * avoid a flash of the default theme
 */
export default (theme) => {
  const [themeState, setThemeState] = useState(false);
  useEffect(() => {
    if (document) {
      const body = document.getElementsByTagName('body')[0];
      body.setAttribute('data-theme', theme);
      setThemeState(true);
    }
  }, [theme]);

  return themeState;
};
