import React from 'react';
import cx from 'classnames';

import styles from './List.module.scss';

const List = ({
  className,
  createListItem,
  items,
  listItemClassName,
  testId,
}) => (
  <ul className={cx(styles.list, className)} data-test-id={testId}>
    {Boolean(items) &&
      items.map((data, index) => (
        <li className={cx(styles.listItem, listItemClassName)} key={index}>
          {createListItem(data)}
        </li>
      ))}
  </ul>
);

List.displayName = 'List';

export default List;
