import React, { useEffect, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import cx from 'classnames';

import { outboundLinkClick } from '~utils/googleAnalytics';
import { urlAddSlashOnTheEnd } from '~utils/helpers';

import styles from './Link.module.scss';

const Link = ({
  ariaLabel,
  children,
  className,
  serif = false,
  target,
  to,
  ...props
}) => {
  const linkClasses = cx(styles.link, className);
  const isRelativeLink = /^\//.test(to);
  const isEmailLink = /^mailto:/.test(to);
  const isPhoneLink = /^tel:/.test(to);
  const [checkedLink, setCheckedLink] = useState(false);
  const [isInternalLink, setIsInternalLink] = useState(null);
  const [linkTarget, setLinkTarget] = useState(target);

  useEffect(() => {
    if (isRelativeLink) {
      setIsInternalLink(true);
    } else {
      setIsInternalLink(false);
    }

    setCheckedLink(true);
  }, [isRelativeLink]);

  useEffect(() => {
    if (!checkedLink) return;
    if (target) {
      setLinkTarget(target);
      return;
    }
    if (isInternalLink || isEmailLink || isPhoneLink) {
      setLinkTarget('_self');
      return;
    }
    setLinkTarget('_blank');
  }, [isInternalLink, checkedLink, isEmailLink, isPhoneLink, target]);

  return (
    <>
      {to && isInternalLink ? (
        <GatsbyLink
          aria-label={ariaLabel}
          className={linkClasses}
          target={linkTarget || undefined}
          to={!(isEmailLink || isPhoneLink) ? urlAddSlashOnTheEnd(to) : to}
          {...props}
        >
          <span className={styles.linkTextWrapper}>{children}</span>
        </GatsbyLink>
      ) : (
        <a
          href={to}
          className={linkClasses}
          rel='noreferrer noopener'
          aria-label={ariaLabel}
          target={linkTarget || '_blank'}
          onClick={() => {
            outboundLinkClick(children, to);
          }}
          {...props}
        >
          <span className={styles.linkTextWrapper}>{children}</span>
        </a>
      )}
    </>
  );
};

Link.displayName = 'Link';

export default Link;
