import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '~components/Global/Layout';
import PageHeader from '~components/Global/PageHeader';

import useRouteInfo from '~hooks/useRouteInfo';
import { clearApplicationName } from '~redux/actions';

const ApplicationSuccess = ({ data, location }) => {
  const { parentRoute } = useRouteInfo();
  const dispatch = useDispatch();
  const name = useSelector((state) => state?.application?.name);

  useEffect(() => {
    /* Prevent access without name registered on data store */
    if (!name) {
      navigate('/careers');
    }

    return () => {
      /* Clear name on unmount */
      dispatch(clearApplicationName());
    };
  }, [name]);

  return data && name ? (
    <>
      <Layout
        data={data}
        parentRoute={parentRoute}
        hideIndexLink
        location={location}
      >
        <PageHeader
          headline={`Thank you ${name}`}
          label={data.label}
          text={data.text}
          title='careers-success'
        />
      </Layout>
    </>
  ) : null;
};

ApplicationSuccess.displayName = 'ApplicationSuccess';

export default ApplicationSuccess;
