import { useCallback, useEffect, useState } from 'react';

import BREAKPOINTS from '~constants/breakpoints';

const useBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState();
  const updateBreakpoint = useCallback(() => {
    BREAKPOINTS.forEach((breakpoint, index) => {
      if (window.innerWidth >= breakpoint.minWidth) {
        if (index + 1 === BREAKPOINTS.length) {
          setCurrentBreakpoint(breakpoint.name);
        } else if (window.innerWidth < BREAKPOINTS[index + 1].minWidth) {
          setCurrentBreakpoint(breakpoint.name);
        }
      }
    });
  }, [setCurrentBreakpoint]);

  useEffect(() => {
    updateBreakpoint();
    window.addEventListener('resize', updateBreakpoint);
    return () => window.removeEventListener('resize', updateBreakpoint);
  }, [updateBreakpoint]);

  return currentBreakpoint;
};

export default useBreakpoint;
