import React from 'react';

import Markdown from '~components/Global/Markdown';

import styles from './Leadership.module.scss';

const Leadership = ({ data }) => (
  <div
    className={styles.leadership}
    role='list'
    data-test-id='leadership-module'
  >
    {Boolean(data) &&
      data.map((leader) => (
        <div className={styles.leader} role='listitem' key={leader.title}>
          <p className={styles.title}>{leader.title}</p>
          {leader.position ? (
            <p className={styles.position}>{leader.position}</p>
          ) : null}
          {leader.about ? (
            <Markdown className={styles.about} source={leader.about} />
          ) : null}
        </div>
      ))}
  </div>
);

Leadership.displayName = 'Leadership';

export default Leadership;
