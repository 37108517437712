import React, { useEffect } from 'react';
import cx from 'classnames';

import Logo from '~assets/svg/Loading.svg';
import styles from './Loading.module.scss';

const Loading = ({ active }) => {
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '1.5rem';
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
  }, [active]);

  const classes = cx({
    [styles.overlay]: true,
    [styles.active]: active,
  });

  return (
    <div className={classes}>
      <div className={styles.loading}>
        <div className={styles.animated}>
          <Logo />
        </div>
      </div>
    </div>
  );
};

Loading.displayName = 'Loading';

export default Loading;
