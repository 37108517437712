import React from 'react';

import GridSection from '~components/Global/GridSection';

import useRouteInfo from '~hooks/useRouteInfo';

import styles from './JobBenefits.module.scss';

const JobBenefits = ({ items, title, type }) => {
  const routeName = useRouteInfo().title;
  if (!title || !items) return null;
  const testId = `${routeName}-${type}-module`;

  return (
    <GridSection
      className={styles.section}
      data-test-id={testId}
      left={title}
      right={
        <ul className={styles.list}>
          {items.map((item) => (
            <li className={styles.item} key={item.title}>
              <p className={styles.title}>{item.title}</p>
              <p className={styles.text}>{item.text}</p>
            </li>
          ))}
        </ul>
      }
    />
  );
};

JobBenefits.displayName = 'JobBenefits';

export default JobBenefits;
