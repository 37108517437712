import React from 'react';
import kebabCase from 'lodash/kebabCase';

import CaseOutcomesSection from '~components/Global/CaseOutcomesSection';
import ContentSection from '~components/Global/ContentSection';
import MultipleContentSection from '~components/Global/MultipleContentSection';
import Press from '~components/Global/Press';
import QuoteBlock from '~components/Global/QuoteBlock';

import Takeaways from '~components/Cases/Takeaways';

/* eslint-disable react/display-name */
const sectionTypes = {
  facts: (data, index) => (
    <CaseOutcomesSection data={data} key={`section-${index}`} />
  ),
  section: (data, index) => (
    <MultipleContentSection data={data} key={`section-${index}`} />
  ),
  quote: (data, index) => (
    <ContentSection type='quote' grid={false} key={`section-${index}`}>
      <QuoteBlock quote={data.quote} />
    </ContentSection>
  ),
  takeaways: (data, index) => <Takeaways {...data} key={`section-${index}`} />,
  text: (data, index) => (
    <ContentSection
      label={data.title}
      text={data.paragraph}
      key={`section-${index}`}
      type={kebabCase(data.title)}
      gallery={data.gallery}
    />
  ),
  press: (data, index) => <Press data={data} key={`section-${index}`} />,
};
/* eslint-enable react/display-name */

function parseSections(sections) {
  return sections.map((section, index) => {
    const { type } = section;
    if (!Object.keys(sectionTypes).includes(type)) {
      return null;
    }
    return sectionTypes[type](section, index);
  });
}

export default parseSections;
