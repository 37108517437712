import React from 'react';
import cx from 'classnames';

import Media from '~components/Global/Media';

import useRouteInfo from '~hooks/useRouteInfo';

import styles from './Hero.module.scss';

const Hero = ({ heroImage, heroVideo }) => {
  const routeName = useRouteInfo().title;
  if (!heroImage && !heroVideo) return null;

  return (
    <div
      data-test-id={`${routeName}-hero-image-module`}
      className={cx(styles.container, { [styles.heroVideo]: heroVideo })}
    >
      <Media
        className={styles.media}
        data={heroImage || heroVideo}
        type='hero'
        hasMaxWidth
      />
    </div>
  );
};

export default Hero;
