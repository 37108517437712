import React from 'react';
import cx from 'classnames';

import styles from './Popover.module.scss';

const Popover = ({ className, isOpen, renderButton, renderContent }) => {
  return (
    <div className={cx(styles.container, className)}>
      {renderButton()}
      <div className={cx(styles.content, { [styles.isVisible]: isOpen })}>
        {renderContent()}
      </div>
    </div>
  );
};

Popover.displayName = 'Popover';

export default Popover;
