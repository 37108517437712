import React from 'react';
import { navigate } from 'gatsby';

import Layout from '~components/Global/Layout';
import PageHeader from '~components/Global/PageHeader';
import Form from '~components/Global/Form';

import formValidations from '~utils/formValidations';
import { sendContactForm } from '~utils/api';

import styles from './Contact.module.scss';

const Contact = ({ data, location }) => {
  const fields = data.form.data.map((field) => ({
    type: field.fieldType,
    name: field.fieldName,
    successMessage: 'Thanks for contacting us!',
    validation: formValidations(field),
    ...field,
  }));

  const onSubmit = async (data) => {
    const formattedData = {};
    // transform select fields into array values
    Object.keys(data).forEach((key) => {
      const field = fields.find((field) => field.name === key);
      if (field && field.type === 'select') {
        formattedData[key] = data[key].split(',');
      } else {
        formattedData[key] = data[key];
      }
    });

    const response = await sendContactForm(formattedData);
    if (response?.status) {
      navigate('/contact/success');
    }

    return response;
  };

  return data ? (
    <Layout hideIndexLink data={data} location={location}>
      <PageHeader className={styles.pageHeader} label={data.label}>
        <Form
          formId='contact-form'
          className={styles.form}
          button={{ label: 'Submit' }}
          buttonClassName={styles.button}
          fields={fields}
          fieldClassName={styles.field}
          mode='onBlur'
          submit={onSubmit}
        />
      </PageHeader>
    </Layout>
  ) : null;
};
Contact.displayName = 'Contact';

export default Contact;
