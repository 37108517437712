import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useReducedMotion } from 'framer-motion';

import Image from '~components/Global/Image';

import useRouteInfo from '~hooks/useRouteInfo';

import styles from './Gallery.module.scss';

/**
 * This gallery should be use only for decorative images that
 * carry no relevant information to the user and for that
 * it has `aria-hidden` set to `true`.
 */

const Gallery = ({ data, sizeSet }) => {
  const DURATION_TRANSITION = 1000;
  const DURATION_STATIC = 3000;
  const reducedMotion = useReducedMotion();

  const [aspectRatio, setAspectRatio] = useState(null);
  const [imageCount, setImageCount] = useState(null);
  const [imageActive, setImageActive] = useState(0);

  const routeName = useRouteInfo().title;

  useEffect(() => {
    if (!data) return;

    calculateAspectRatio(data);
    setImageCount(data.length);
  }, [data]);

  useEffect(() => {
    if (imageCount <= 0) return;

    if (reducedMotion) {
      return;
    }

    let index = 0;
    const interval = setInterval(() => {
      index++;
      index = index === imageCount ? 0 : index;
      setImageActive(index);
    }, DURATION_STATIC + DURATION_TRANSITION);

    return () => clearInterval(interval);
  }, [reducedMotion, imageCount]);

  // calculates aspect ratio with the max values for width and height
  const calculateAspectRatio = (data) => {
    if (data.length <= 0) return;

    const measurements = data.reduce((acc, curr) => {
      const { height, width } = curr;

      if (!acc) {
        return {
          height,
          width,
        };
      }

      return {
        height: height > acc.height ? height : acc.height,
        width: width > acc.width ? width : acc.width,
      };
    }, null);

    setAspectRatio((measurements.height / measurements.width) * 100);
  };

  const getImages = (data) => {
    return data.map((image, index) => {
      const imageClasses = cx({
        [styles.image]: true,
        [styles.active]: index === imageActive,
      });

      return (
        <Image
          data-active
          className={imageClasses}
          image={{
            uri: image.uri,
            alt: image.alt,
          }}
          key={image.alt}
          ratio={image.width / image.height}
          sizeSet={sizeSet}
        />
      );
    });
  };

  return (
    <div
      data-test-id={`${routeName}-gallery-module`}
      aria-hidden
      aria-live='polite'
      className={styles.gallery}
      style={{
        '--gallery-transition-duration': `${DURATION_TRANSITION}ms`,
        paddingBottom: `${aspectRatio}%`,
      }}
    >
      {data && getImages(data)}
    </div>
  );
};

Gallery.displayName = 'Gallery';

export default Gallery;
