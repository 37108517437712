import React from 'react';

import GridSection from '~components/Global/GridSection';
import Markdown from '~components/Global/Markdown';

import styles from './MultipleContentSection.module.scss';

const MultipleContentSection = ({ data }) => {
  if (!data || !data.items) {
    return null;
  }

  return (
    <GridSection
      className={styles.section}
      left={data.label}
      right={
        <>
          {data.items.map(({ title, text }, index) => (
            <div key={index} className={styles.item}>
              {title && <h3 className={styles.title}>{title}</h3>}
              {text && <Markdown className={styles.text} source={text} />}
            </div>
          ))}
        </>
      }
    />
  );
};

MultipleContentSection.displayName = 'MultipleContentSection';

export default MultipleContentSection;
