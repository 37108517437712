import React from 'react';
import { navigate } from 'gatsby';

import Form from '~components/Global/Form';
import Markdown from '~components/Global/Markdown';

import { subscribeToNewsletter } from '~utils/api';
import { sendNewsletterEvent } from '~utils/analytics';

import styles from './Subscribe.module.scss';

const form = {
  button: {
    label: 'Subscribe and agree',
  },
  fields: [
    {
      type: 'text',
      label: 'Email',
      name: 'email',
      successMessage: 'Nice to meet you',
      validation: {
        required: 'Required',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Are you sure this is right?',
        },
      },
    },
  ],
};

const Subscribe = ({ className, disclaimerText }) => {
  const onSubmit = async (data) => {
    const response = await subscribeToNewsletter(data);
    if (response.status) {
      sendNewsletterEvent();
      navigate('/newsletter/success');
    }
    return response;
  };

  return (
    <div className={className} data-test-id='newsletter-subscribe-component'>
      <>
        <Form
          formId='newsletter-subscribe'
          mode={'onChange'}
          {...form}
          fieldClassName={styles.field}
          buttonClassName={styles.button}
          className={styles.form}
          submit={onSubmit}
        />
        {disclaimerText && (
          <Markdown
            className={styles.disclaimer}
            source={disclaimerText}
          ></Markdown>
        )}
      </>
    </div>
  );
};

Subscribe.displayName = 'Subscribe';

export default Subscribe;
