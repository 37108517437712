import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ metadata, lang = 'en' }) => {
  const { page } = metadata || {};

  const metatags = (data) => {
    if (!data) return;

    const ogTags = data.og ? getOgTags(data.og) : [];
    const tags = [
      {
        name: 'description',
        content: page?.pageDescription,
      },
      ...ogTags,
    ];
    return tags;
  };

  const getOgTags = (data) => {
    if (!data) return;

    const title = data.ogTitle && {
      name: 'og:title',
      content: data.ogTitle,
    };
    const type = data.ogType && {
      name: 'og:type',
      content: data.ogType,
    };
    const description = data.ogDescription && {
      name: 'og:description',
      content: data.ogDescription,
    };
    const url = data.ogUrl && {
      name: 'og:url',
      content: data.ogUrl,
    };
    return [url, title, description, type];
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={metatags(metadata)}
    >
      <title>{page?.pageTitle}</title>
    </Helmet>
  );
};

SEO.displayName = 'SEO';

export default SEO;
