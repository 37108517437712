import React, { useState } from 'react';
import cx from 'classnames';

import Link from '~components/Global/Link';
import Markdown from '~components/Global/Markdown';

import styles from './Footer.module.scss';

const COMPANY_NAME = 'AKDM';
const links = [
  {
    url: '/privacy',
    title: 'Privacy Policy',
    testid: 'footer-privacy-policy-link',
  },
];

const contactLinks = [
  {
    url: '/contact',
    title: 'Contact',
    testid: 'footer-contact-link',
  },
  {
    url: '/newsletter',
    title: 'Newsletter',
    testid: 'footer-newsletter-link',
  },
];

const Footer = ({ className, data }) => {
  const [year] = useState(new Date().getFullYear());

  if (!data) return null;

  const getAddress = (office) => {
    const testId = 'footer-address-link';
    const addressMarkdown = (
      <Markdown
        data-test-id={testId}
        className={styles.address}
        source={office.addressZip}
      />
    );

    return office.mapLink ? (
      <Link
        data-test-id={testId}
        className={styles.footerLink}
        to={office.mapLink}
        target='_blank'
      >
        {addressMarkdown}
      </Link>
    ) : (
      addressMarkdown
    );
  };

  const getTelephone = (office) => {
    const { telephone } = office;
    const digits = telephone.replace(/\D/g, '');
    const intlPhone = `tel:+1${digits}`;
    return (
      <Link
        aria-label={`telephone number ${telephone
          .replace(/\D/g, '')
          .split('')
          .join(' ')}`}
        data-test-id='footer-phone-link'
        className={cx(styles.footerLink, styles.phone)}
        to={intlPhone}
      >
        {telephone}
      </Link>
    );
  };

  const getLinks = (data) => {
    return data.map((link) => {
      return (
        <li key={link.title}>
          <Link
            data-test-id={link.testid}
            className={styles.footerLink}
            to={link.url}
          >
            {link.title}
          </Link>
        </li>
      );
    });
  };

  return (
    <footer className={cx(styles.footer, className)}>
      <hr className={styles.footerHr} />
      <div className={cx(styles.footerItem, styles.companyName)}>
        <label className={styles.title}>{COMPANY_NAME}</label>
        <ul className={styles.list}>{getLinks(links)}</ul>
      </div>

      <div className={styles.footerItem}>
        <label className={styles.title}>Stay in touch</label>
        <ul className={styles.list}>{getLinks(contactLinks)}</ul>
      </div>

      <div className={styles.footerItem}>
        <label className={styles.title}>Address</label>
        <address className={styles.address}>
          {getAddress(data.office)}
          {getTelephone(data.office)}
        </address>
      </div>

      <div className={styles.copyright}>
        <hr className={styles.copyrightHr} />
        <div className={cx(styles.footerItem, styles.copyrightYear)}>
          &copy; {year} Acknowledge Digital LLC.
        </div>
        <div className={cx(styles.footerItem, styles.copyrightText)}>
          All rights reserved.
        </div>
        <div className={cx(styles.footerItem, styles.mobileCopyright)}>
          &copy; {year} Acknowledge Digital LLC. All rights reserved.
        </div>
        <span className={styles.mobileCompanyNameText}>{COMPANY_NAME}</span>
      </div>
    </footer>
  );
};

Footer.displayName = 'Footer';

export default Footer;
