import { useCallback, useEffect, useRef } from 'react';

import useContentController from './useContentController';

import useIsInView from '~hooks/useIsInView';
import useScrollWatcher from '~hooks/useScrollWatcher';

const TIME_DURATION = 9000;

const ContentRollerController = ({ length, children }) => {
  const { state, isPlaying, resume, pause } = useContentController({
    length,
  });
  const { isInView, ref } = useIsInView();
  const { lastScroll } = useScrollWatcher();
  const currentInterval = useRef();
  const pauseWhenNotScrolling = useRef(() => {});

  pauseWhenNotScrolling.current = useCallback(() => {
    // Check if the last scroll was more than 9 seconds ago
    if (isPlaying && new Date().getTime() - lastScroll >= TIME_DURATION) {
      pause();
    }
  }, [isPlaying, lastScroll, pause]);

  // Check every 5 seconds when was the last time scroll changed.
  useEffect(() => {
    currentInterval.current = setInterval(() => {
      pauseWhenNotScrolling.current();
    }, 5000);

    return () => {
      clearInterval(currentInterval.current);
    };
  }, []);

  // If the component is not in view, the animation should also pause and resume from the same point, when in view again.
  useEffect(() => {
    if (typeof isInView !== 'boolean') {
      return;
    }

    const stoppedScrolling = new Date().getTime() - lastScroll >= TIME_DURATION;
    /* Resume animation if the component is in the viewport, is not playing, and didn't stop scrolling the page */
    if (isInView && !isPlaying && !stoppedScrolling) {
      resume();
    } else if (
      /* Pause animation if component is not in viewport anymore */
      !isInView &&
      isPlaying
    ) {
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView, isPlaying, lastScroll]);

  if (!children) {
    return null;
  }

  return children({
    state,
    isPlaying,
    resume,
    pause,
    ref,
  });
};

export default ContentRollerController;
