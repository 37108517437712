import React from 'react';
import cx from 'classnames';

import Markdown from '~components/Global/Markdown';
import styles from './PageHeader.module.scss';

const PageHeader = (data) => {
  if (!data) return null;
  const {
    className,
    children,
    label,
    isLabelHeading = true,
    headline,
    text,
    title,
    ...props
  } = data;

  const LabelComponent = headline ? (isLabelHeading ? 'h2' : 'div') : 'h1';

  return (
    <section className={cx(styles.section, className)} {...props}>
      {headline && (
        <h1 className={styles.headline} data-test-id={`${title}-header`}>
          {headline}
        </h1>
      )}
      {Boolean(label) && (
        <LabelComponent
          className={styles.label}
          data-test-id={`${title}-module-name`}
        >
          {label}
        </LabelComponent>
      )}
      {(text || children) && (
        <div className={cx(styles.text, { [styles.noHeadline]: !headline })}>
          <Markdown data-test-id={`${title}-subheader`} source={text} />
          {children}
        </div>
      )}
    </section>
  );
};

PageHeader.displayName = 'PageHeader';

export default PageHeader;
