import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { m as motion, useReducedMotion } from 'framer-motion';

import TextVerticalRoller from '../TextVerticalRoller';

import { SupportPosition } from '~components/Global/SingleOutcome';
import useWindowResize from '~hooks/useWindowResize';
import getRenderedSize from '~utils/getRenderedSize';

import styles from './ContentRollerSupport.module.scss';

const ContentRollerSupport = ({
  support,
  textWidth,
  supportClassName,
  containerWidth,
  text,
  textPosition,
}) => {
  const [containerDimensions, setContainerDimensions] = useState({});
  const { width: windowWidth } = useWindowResize();
  const isReducedMotion = useReducedMotion();

  useEffect(() => {
    if (!support) {
      return;
    }
    getRenderedSize(
      <div className={supportClassName}>{support}</div>,
      containerWidth - textWidth
    ).then((dimension) => setContainerDimensions(dimension));
  }, [
    support,
    setContainerDimensions,
    windowWidth,
    textWidth,
    containerWidth,
    supportClassName,
  ]);

  return support && textWidth ? (
    <motion.div
      className={cx(styles.element, styles.support, {
        [styles.sub]: textPosition === SupportPosition.SUB,
      })}
      style={{
        overflow: 'hidden',
        ...containerDimensions,
      }}
      initial={isReducedMotion ? { x: textWidth + 0.5, y: 0 } : false}
      animate={!isReducedMotion ? { x: textWidth + 0.5, y: 0 } : {}}
      transition={{ ease: 'linear', duration: 0.5 }}
    >
      <TextVerticalRoller
        motionKey={`${support}-${text}`}
        className={styles.element}
        delay={0.15}
      >
        <div className={supportClassName}>{support}</div>
      </TextVerticalRoller>
    </motion.div>
  ) : null;
};

ContentRollerSupport.displayName = 'ContentRollerSupport';

export default ContentRollerSupport;
